<template>
  <el-card shadow="hover">
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="Index"
      >
        <template #default="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.index }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="任务名称"
          width="600">
        <template #default="scope">
          <el-row>{{ scope.row.name }}</el-row>
        </template>
      </el-table-column>
      <el-table-column
          label="任务创建时间"
          width="200">
        <template #default="scope">
          <el-row>{{ scope.row.time }}</el-row>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button
              size="mini"
              type="danger"
              @click="deleteLog(scope.row.name)">删除日志
          </el-button>
          <el-button
              size="mini"
              @click="getLog(scope.row.name)">查看日志
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog v-model="logDigLog" width="80%">
    <el-card v-loading="logLoading">
      <el-button type="primary" @click="TaskLogToExcel">导出为Excel</el-button>
      <el-button type="warning" @click="getTaskLog">刷新日志</el-button>
      <el-input
          type="textarea"
          :rows="logInfo.logRow"
          placeholder="任务日志"
          v-model="logInfo.logText">
      </el-input>
    </el-card>

  </el-dialog>
</template>

<script>
import {ElMessageBox} from "element-plus";

export default {
  data() {
    return {
      tableData: [],
      logName: "",
      logInfo: {
        logRow: "",
        logText: "",
      },
      logDigLog: false,
      logLoading: false
    }
  },
  methods: {
    deleteLog(name) {
      this.$api.GUIAPI.deleteLog(name).then()
    },
    getLog(name) {
      this.logName = name
      this.logDigLog = true
      this.getTaskLog().then(rep => {
        if (rep.data.success) {
          this.logDigLog = true
        }
      })
    },
    getTableDatas() {
      this.$api.GUIAPI.logs().then(rep => {
        if (rep.data.success) {
          this.tableData = rep.data.data
          console.log(this.tableData)
        }
      })
    },
    TaskLogToExcel() {
      // const encoder = new TextEncoder('utf-8');
      // const csvArrayBuffer = encoder.encode(this.logInfo.logText);
      let taskName = this.logName
      // 创建Blob对象
      const blob = new Blob(['\uFEFF', this.logInfo.logText], {type: 'text/csv;charset=utf-8;'});
      // 创建下载链接
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = taskName + '.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTaskLog() {
      this.logLoading = true
      return this.$api.GUIAPI.getLog(this.logName).then(rep => {
        if (rep.data.success) {
          this.logInfo = rep.data.data
          console.log(this.logInfo)
        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      }).finally(() => {
        this.logLoading = false
      })
    }
  },
  beforeMount() {
    this.getTableDatas()
  }
}
</script>