import {createRouter, createWebHashHistory} from 'vue-router'
import LoginWeChat from "@/components/LoginWeChat.vue";
import Navigate from "@/components/Navigate.vue";
import User from '@/components/User.vue'
import ShoppingCart from "@/components/SnappedSuccess.vue";
import Task from "@/components/Task.vue";
import SnappedSuccess from "@/components/SnappedSuccess.vue";
import Config from "@/components/Config.vue";
import LoginAdmin from "@/components/LoginAdmin.vue";
import OneUserInfo from "@/components/OneUserInfo.vue";
import GUITask from "@/components/GUITask.vue";
import FastOrder from "@/components/FastOrder.vue";
import NewTask from "@/components/NewTask.vue";
import Test from "@/components/Test.vue";

const router = new createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: "/",
        name: "LoginWeChat",
        component: LoginWeChat
    }, {
        path: "/user",
        name: "OneUser",
        component: OneUserInfo
    }, {
        path: "/user/order",
        name: "FastOrder",
        component: FastOrder
    },
        {
            path: "/admin",
            name: "admin",
            component: Navigate,
            children: [

                {
                    path: "/admin/user",
                    name: "User",
                    component: User
                },
                {
                    path: "/admin/task",
                    name: "Task",
                    component: Task
                },
                {
                    path: "/admin/gui",
                    name: "GUI",
                    component: GUITask
                },
                {
                    path: "/admin/buy",
                    name: 'ShoppingCart',
                    component: SnappedSuccess
                },
                {
                    path: "/admin/config",
                    name: 'Config',
                    component: Config
                },
                {
                    path: "/admin/newTask",
                    name: 'newTask',
                    component: NewTask
                },
            ],

        }
        , {
            path: "/admin/login",
            name: "LoginAdmin",
            component: LoginAdmin
        },
    ]

})

export default router
