<template>
  <div style="height: 100vh;display: flex;flex-direction:column;align-items: center;">
    <div style="height:20%;width: 100vw;margin-bottom: 3vh;margin-top: 10vh">
      <span style="font-size: 3em">自助查单</span>
    </div>
    <el-card shadow="hover"
             style="display: flex;justify-items: center;justify-content: center;align-items: center;width: 90vw">
      <el-row></el-row>
      <el-form label-width="80px">
        <el-form-item label="手机号">
          <el-input placeholder="请输入上号手机号" v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="推送URL" v-if="haveData">
          <el-input placeholder="请输入推送URL(点击更新信息可修改)" v-model="push_url"></el-input>
        </el-form-item>
        <el-row v-if="haveData">
          <el-col>微信ID:{{ userInfo.wechat_info.wxid }}
          </el-col>
          <el-col v-if="userInfo.wechat_info.online" style="color: green">在线中</el-col>
          <el-col v-if="!userInfo.wechat_info.online" style="color: red">不在线</el-col>
          <el-col>OpenID:{{
              userInfo.popmart_info.open_id ? userInfo.popmart_info.open_id : "未获取泡泡玛特信息"
            }}
          </el-col>
          <el-col>收货地址:{{
              userInfo.popmart_info.address ? userInfo.popmart_info.address.text + userInfo.popmart_info.address.name : '无收货地址'
            }}
          </el-col>
        </el-row>

        <el-form-item>
          <el-button @click="getUserInfo" type="success">查询账号信息</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="TwoLoginAndCheck" v-if="!userInfo.wechat_info.online&&userInfo.wechat_info.wxid"
                     :loading="twoLoginData.loading">
            一键推送登录
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="Logout" v-if="userInfo.wechat_info.wxid" type="warning">退出抢购(下号)</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="updateInfo" type="primary" v-if="userInfo.wechat_info.wxid">一键更新账号信息</el-button>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-button type="warning" v-if="haveData" @click="updatePushUrl">修改推送Url|UID</el-button>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="danger " @click="$router.push({'name':'FastOrder','query':{group:phone}})" size="large">快速查单|分组查单
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

  </div>

</template>

<script>
import QRCode from "qrcodejs2";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "OneUserInfo",
  data() {
    return {
      phone: '',
      push_url: '',
      twoLoginData: {
        loading: false,

      },
      userInfo: {
        wechat_info: {
          wxid: null,
        },
        popmart_info: {
          address: "..."
        }
      },
      tableData: [],
      haveData: false,
    }
  },
  methods: {
    Logout() {
      this.$api.deleteWeChatInfo({"wxid": this.userInfo.wechat_info.wxid}).then(rep => {
        if (rep.data.success) {
          ElMessageBox.alert('下号成功:' + rep.data.msg)
        }
      }).finally(() => {

      })
    },
    copyText(value) {
      location.href = value
    },

    TwoLoginAndCheck() {
      if (!this.phone) {
        ElMessageBox.alert('手机号未填写!')
        return
      }
      this.twoLoginData.loading = true
      this.$api.TwoLoginAndCheck({phone: this.phone}).then(rep => {
        if(rep.data.success){
          ElMessageBox.alert('已推送登录,请尽快手机点击确认!')
        }else{
          ElMessageBox.alert('推送失败,请扫码登录!')
        }
      }).finally(() => {
        this.twoLoginData.loading = false
      })
    },
    getUserInfo() {
      this.$api.OneUserGetUserInfo({phone: this.phone}).then(rep => {
        if (!rep.data.success) {
          ElMessageBox.alert(rep.data.msg)
          this.haveData = false
          return
        }

        this.userInfo = rep.data.data
        this.push_url = this.userInfo.wechat_info.push_url
        this.haveData = true

      })
    },
    getOrderList() {
      this.tableData.forEach(item => {
        if (item.order && item.order.order_no) {
          let ele = document.getElementById(item.order.order_no)
          ele ? ele.innerHTML = '' : null
        }
      })
      this.$api.OneUserGetOrderList({phone: this.phone}).then(resp => {
        this.tableData = resp.data.data;
        this.tableData.forEach(item => {
          this.$nextTick(() => {
            new QRCode(item.order.order_no, {
              text: item.order.pay_url,
              width: 100,
              height: 100,
              colorDark: "#333333",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.L,
            });
          })
        })
        return resp
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    updatePushUrl() {
      console.log(666)
      this.$api.OneUserUpdatePushUrl({phone: this.phone, push_url: this.push_url}).then(rep => {
        if (!rep.data.success) {
          ElMessageBox.alert(rep.data.msg)
        }
      })
    },
    updateInfo() {
      if (!this.userInfo) {
        ElMessageBox.alert('请先获取用户信息!')
        return
      }
      this.updatePushUrl()
      this.getUserInfo()
    },
    selectInfo() {
      this.getUserInfo()
      this.getOrderList()
    },
  },
  mounted() {

    this.$watermark.remove()
  }, beforeUnmount() {
    this.$watermark.create();
  }
}
</script>

<style scoped>

</style>