<template class="box1">
  <el-card shadow="hover" style="width: 80vw;display: inline-block;margin-top: 10vh">
    <el-form>
      <el-form-item><h2>PPCar-分组快捷查单</h2></el-form-item>
      <el-form-item label="请输入组ID或者手机号或者wxid">
        <el-input placeholder="请输入组ID或者手机号或者wxid" v-model="group"></el-input>
      </el-form-item>
      <el-form-item label="自动刷新">
        <el-switch
            v-model="autoFlush"
            active-text="开启"
            inactive-text="关闭"
            @change="handlerFlush"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="开启声音播报">
        <el-switch
            v-model="video"
            active-text="开启"
            inactive-text="关闭"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="刷新">
        <el-button @click="fuzzySearchClick" type="success">手动刷新</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card shadow="hover" style="width: 80vw;display: inline-block;margin-top: 2vh">
    <el-table :data="tableData" style="width: 100%" border :cell-style="{textAlign:'center'}"
              :header-cell-style="{textAlign:'center'}">
      <el-table-column label="ID" width="50" align="center">
        <template #default="scope">
          <div>
            <span>{{ scope.row.id }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="云闪付支付链接" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.orderInfo.payUrl && scope.row.orderInfo.payUrl.ysf"
               @click="copyText(scope.row.orderInfo.payUrl.ysf)">
            <a :href="scope.row.orderInfo.payUrl.ysf">
              {{ scope.row.orderInfo.payUrl.ysf }}
            </a>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="微信支付链接" :show-overflow-tooltip="true" :show="false">
        <template #default="scope">
          <div v-if="scope.row.orderInfo.payUrl && scope.row.orderInfo.payUrl.wxpay"
               @click="copyText(scope.row.orderInfo.payUrl.wxpay)">
            <a :href="scope.row.orderInfo.payUrl.wxpay">
              {{ scope.row.orderInfo.payUrl.wxpay }}
            </a>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="支付QR|点击放大" width="150" align="center">
        <template #default="scope">
          <!--              {{scope.row.order.order_no}}-->
          <div v-if="scope.row.orderInfo.payUrl" :id="scope.row.orderInfo.orderNo"
               @click="bigQr(scope.row.orderInfo.payUrl)"></div>
        </template>
      </el-table-column>
      <el-table-column label="归属组" :show-overflow-tooltip="true" width="100">
        <template #default="scope">
          <div>
            <span>{{ scope.row.userInfo.group_wxid }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下单商品">
        <template #default="scope">
          <div>
            <span>{{ scope.row.skuInfo.skuName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下单店铺" width="120">
        <template #default="scope">
          <div>
            <span>{{ scope.row.storeInfo.storeName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下单微信">
        <template #default="scope">
          <div>
            <span v-html=" scope.row.userInfo.wxid"></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下单手机号" :show-overflow-tooltip="true" width="100">
        <template #default="scope">
          <div>
            <span>{{ scope.row.userInfo.phone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单号" width="150">
        <template #default="scope">
          <div>
            <span>{{ scope.row.orderInfo.orderNo }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单创建时间" width="120">
        <template #default="scope">
          <div>
            <span>{{ scope.row.orderInfo.createTimeText }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="100">
        <template #default="scope">
          <div>
            <span v-if="scope.row.orderInfo.status===0" style="color:red;">未支付</span>
            <span v-if="scope.row.orderInfo.status===1" style="color:green;">已支付</span>
            <span v-if="scope.row.orderInfo.status===2" style="color:orange;">已取消</span>
            <span v-if="scope.row.orderInfo.status===3" style="color:gray;">未知状态</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button
              size="small"
              type="warning"
              @click="updatePayUrl(scope.row)"
              v-if="![1,2].includes(scope.row.orderInfo.status)"
          >支付链接|更新状态
          </el-button>
        </template>
      </el-table-column>

    </el-table>
  </el-card>
  <el-dialog v-model="isBig" width="80vw" height="80vh" align-center="true" >
      <el-form>
        <el-form-item>
          <div id="bigQr" style="height: 30vh;display: flex;justify-content: center;align-items: center;flex-direction: column"></div>
        </el-form-item>
        <el-form-item style="margin-top: 10vh">
          <el-button type="success" @click="bigQr(this.nowBigQrUrls,'wxpay')">微信支付(绿码)</el-button>
          <el-button type="danger" @click="bigQr(this.nowBigQrUrls,'ysf')">云闪付(红码)</el-button>
        </el-form-item>
      </el-form>

  </el-dialog>
  <audio ref="audio">
    <source src="@/assets/fuqian.mp3"/>
  </audio>
</template>

<script>
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
import QRCode from "qrcodejs2";

export default {
  name: "FastOrder",
  data() {
    return {
      group: "",
      video: false,
      autoFlush: false,
      tableData: [],
      autoFlushTimer: null,
      nowInfo: [],
      nowOrderIDs: [],
      first: true,
      isBig: false,
      nowBigQrUrls:{}
    }
  },
  methods: {

    bigQr(payUrls,type) {
      this.nowBigQrUrls = payUrls
      if(!type){
        if(payUrls.ysf){
          type = 'ysf'
        }else{
          type = 'wxpay'
        }
      }else{
        if(type==='ysf'&&!payUrls.ysf){
          ElMessage.warning('云闪付支付链接未生成')
          return;
        }
      }
      if(!payUrls.ysf &&!payUrls.wxpay){
        ElMessage.warning('支付链接未生成')
        return
      }
      let ele = document.getElementById('bigQr')
      ele ? ele.innerHTML = '' : null
      this.isBig = true

      if(type==='ysf'){
        this.$nextTick(() => {
          let url = null
          let color = '#F6443F'
          url = payUrls.ysf
          new QRCode('bigQr', {
            // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
            text: url,
            width: 250,
            height: 250,
            colorDark: color,
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.L,
          });
        })
      }else{
        this.$nextTick(() => {
          let url = payUrls.wxpay_qr
          let color = '#26D86F'
          new QRCode('bigQr', {
            // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
            text: url,
            width: 250,
            height: 250,
            colorDark: color,
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.L,
          });
        })
      }


    },
    playAudioTwice() {
      const audio = this.$refs.audio;
      let x = 0
      audio.play()
      let autoTimer = setInterval(() => {
        if (x > 3) {
          window.clearInterval(autoTimer)
          return
        }
        x += 1
        audio.play();
      }, 2000)

    },
    copyText(value) {
      navigator.clipboard.writeText(value).then(() => {
        ElMessage.success('复制成功')
      }).catch(() => {
        ElMessage.warning('复制失败')

      });

    },
    updatePayUrl(orderInfo) {
      this.$api.upDataPayUrl(orderInfo).then(rep => {
        if (rep.success) {
          let ele = document.getElementById(orderInfo.orderNo)
          ele ? ele.innerHTML = '' : null

          this.$nextTick(() => {
            let url = null
            let color = '#F6443F'
            let data = rep.data.data
            if (data.payUrl.ysf) {
              url = data.payUrl.ysf
            } else {
              url = data.payUrl.wxpay_qr
              color = '#26D86F'
            }

            new QRCode(orderInfo.orderNo, {
              // text: IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
              text: url,
              width: 100,
              height: 100,
              colorDark: color,
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.L,
            });
          })
        }
      })
    },
    fuzzySearchClick() {
      this.getSnappedSuccess({group: this.group}).then(resp => {
        if (resp.data.code === this.$CODE.SUCCESS) {
        }
      })
    },
    getSnappedSuccess(params) {
      this.isLoading = true
      return this.$api.getSnappedSuccess(params).then(resp => {
        if (this.first) {

        }
        this.tableData = resp.data.data
        let newOrder = []
        let newOrderID = []
        let needUpdate = false
        let isNew = false

        for (let order of resp.data.data) {
          let info = `${order.orderInfo.orderNo}-${order.orderInfo.payUrl.wxpay}-${order.orderInfo.payUrl.ysf}`
          newOrder.push(info)
          newOrderID.push(order.orderInfo.orderNo)
          if (!needUpdate && !this.nowInfo.includes(info)) {
            needUpdate = true
          }
          if (!isNew && !this.nowOrderIDs.includes(order.orderInfo.orderNo)) {
            isNew = true
            ElNotification({
              title: '新订单来袭',
              message: '有新订单出现!!!',
              type: 'warning',
            })

            this.video ? this.playAudioTwice() : null

          }

        }
        this.nowInfo = newOrder
        this.nowOrderIDs = newOrderID
        if (needUpdate) {
          this.tableData.forEach(item => {
            if (item.orderInfo && item.orderInfo.orderNo) {
              let ele = document.getElementById(item.orderInfo.orderNo)
              ele ? ele.innerHTML = '' : null
            }

            this.$nextTick(() => {
              let url = null
              let color = '#F6443F'
              if([1,2].includes(item.orderInfo.status)){
                let ele = document.getElementById(item.orderInfo.orderNo)
                ele ? ele.innerHTML = '取消/已支付不显示二维码' : null
                return
              }
              if (item.orderInfo.payUrl.ysf) {
                url = item.orderInfo.payUrl.ysf
              } else {
                url = item.orderInfo.payUrl.wxpay_qr
                color = '#26D86F'
              }
              new QRCode(item.orderInfo.orderNo, {
                // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
                text: url,
                width: 100,
                height: 100,
                colorDark: color,
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.L,
              });
            })
          })
        }


        return resp
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    pay2Qr(pay_url) {
      // ElMessageBox.alert('该方法为高级版专属!')
      // var qrcode = new QRCode(this.$refs.qrCodeUrl, {
      //   text: pay_url, // 需要转换为二维码的内容
      //   width: 100,
      //   height: 100,
      //   colorDark: '#000000',
      //   colorLight: '#ffffff',
      //   correctLevel: QRCode.CorrectLevel.H
      // })
    },
    deleteData(ordNos) {
      this.$api.deleteSnappedSuccess({"orders_no": ordNos}).then(rep => {
        if (rep.data.success) {
          this.tableData = this.tableData.filter(item => !ordNos.includes(item.orderInfo.orderNo))
        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      })
    },
    deleteAllData() {
      let deleteOrders = []
      for (let order of this.tableData) {
        deleteOrders.push(order.orderInfo.orderNo)
      }
      this.deleteData(deleteOrders)
      this.getSnappedSuccess()
    },
    GetOrderInfo(order) {
      let order_no = order.orderInfo.orderNo
      let wxid = order.userInfo.wxid
      this.$api.GetOrderInfo({"order_no": order_no, "wxid": wxid,}).then(rep => {
        if (rep.data.success) {
          let data = rep.data.data.wait_pay_order
          let orderStore = data.sg_order_list[0].store_name
          let goods = data.sg_order_list[0].goods_list[0]
          let orderGoods = goods.name
          let orderGoodsCount = goods.order_num
          let orderGoodsPrice = data.sg_order_list[0].total_price / 100
          let OrderStatus = data.sg_source_order.status === 1 ? '待支付' : "已取消!"
          ElMessageBox.alert(
              '当前订单状态:<br>' + `<div style="color: red">${OrderStatus}</div>` + `<div>店铺:${orderStore}</div>` + `<div>商品:${orderGoods}</div>` + `<div> 数量:${orderGoodsCount} 总价格:${orderGoodsPrice}</div>`,
              '用户:' + ordNo.user.phone + "订单-" + order_no,
              {"dangerouslyUseHTMLString": true,}
          )


        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      })
    },
    fuzzyDataToExcel() {
      this.isLoading = true
      this.$api.DownLoadSnappedRecord().finally(() => {
        this.isLoading = false
      })
    },
    handlerFlush(newVal) {
      console.log(newVal)
      if (this.autoFlushTimer) {
        window.clearInterval(this.autoFlushTimer)
      }
      if (newVal) {
        ElMessage.success('开启自动刷新,刷新中...')
        this.autoFlushTimer = setInterval(() => {
          this.fuzzySearchClick()
        }, 3000)

      } else {
        ElMessage.warning('自动刷新已关闭!!!')
      }
    },

  },

  mounted() {
    if(this.$route.query.group){
      this.group = this.$route.query.group
      this.autoFlush = true
      this.fuzzySearchClick()
    }
  },
  beforeUnmount() {
    if (this.autoFlushTimer) {
      clearInterval(this.autoFlushTimer)
    }
  }
}
</script>

<style scoped>

</style>