<template>
  <el-card shadow="hover">

    <div style="padding: 24px;">
      <div style="width: 100%;margin-bottom: 24px;">
        <el-form>
          <el-form-item>
            <el-input v-model="word" placeholder="根据关键词搜索(任务/商品/店铺名)"/>

          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="timeSelect"
                type="datetimerange"
                :shortcuts="shortcuts"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"></el-date-picker>
          </el-form-item>
          <el-form-item>            <el-button @click="fuzzySearchClick" type="primary" :loading="isLoading">搜索</el-button>

          </el-form-item>
          <el-form-item>
            <el-button @click="fuzzyDataToExcel" type="primary" :loading="isLoading">导出Excel</el-button>

          </el-form-item>
          <el-form-item>
            <el-button @click="$router.push({'name':'FastOrder'})" type="success">分组查单</el-button>

          </el-form-item>
          <el-form-item>

            <el-popconfirm title="确定要删除所有任务记录,该操作不可撤回!" confirm-button-text="确认删除"
                           cancel-button-text="取消" @confirm="deleteAllData">
              <template #reference>
                <el-button type="danger" :loading="isLoading">删除当页所有记录</el-button>
              </template>
            </el-popconfirm>
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%;">
        <el-table :data="tableData" style="width: 100%" border :cell-style="{textAlign:'center'}"
                  :header-cell-style="{textAlign:'center'}" v-loading="isLoading">
          <el-table-column label="ID" width="50" align="center">
            <template #default="scope">
              <div>
                <span>{{ scope.row.id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="下单商品">
            <template #default="scope">
              <div>
                <span>{{ scope.row.skuInfo.skuName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="下单店铺" width="120">
            <template #default="scope">
              <div>
                <span>{{ scope.row.storeInfo.storeName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="下单微信">
            <template #default="scope">
              <div>
                <span v-html=" scope.row.userInfo.wxid"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="下单手机号" :show-overflow-tooltip="true" width="100">
            <template #default="scope">
              <div>
                <span>{{ scope.row.userInfo.phone }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单号" width="150">
            <template #default="scope">
              <div>
                <span>{{ scope.row.orderInfo.orderNo }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="归属组" :show-overflow-tooltip="true" width="100">
            <template #default="scope">
              <div>
                <span>{{ scope.row.userInfo.group_wxid }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单创建时间" width="120">
            <template #default="scope">
              <div>
                <span>{{ scope.row.orderInfo.createTimeText }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" width="100">
            <template #default="scope">
              <div>
                <span v-if="scope.row.orderInfo.status===0" style="color:red;">未支付</span>
                <span v-if="scope.row.orderInfo.status===1" style="color:green;">已支付</span>
                <span v-if="scope.row.orderInfo.status===2" style="color:orange;">已取消</span>
                <span v-if="scope.row.orderInfo.status===null" style="color:gray;">未知状态</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="150">
            <template #default="scope">
              <push-msg :url="scope.row.userInfo.push_url"></push-msg>
              <el-button
                  size="small"
                  type="danger"
                  @click="deleteData([scope.row.orderInfo.orderNo])"
              >删除记录
              </el-button>
              <el-button
                  size="small"
                  type="warning"
                  @click="updatePayUrl(scope.row)"
                  v-if="![1,2].includes(scope.row.orderInfo.status)"
              >支付链接|更新状态
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="云闪付支付链接" :show-overflow-tooltip="true">
            <template #default="scope">
              <div v-if="scope.row.orderInfo.payUrl && scope.row.orderInfo.payUrl.ysf"
                   @click="copyText(scope.row.orderInfo.payUrl.ysf)">
                {{ scope.row.orderInfo.payUrl.ysf }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="微信支付链接" :show-overflow-tooltip="true">
            <template #default="scope">
              <div v-if="scope.row.orderInfo.payUrl && scope.row.orderInfo.payUrl.wxpay"
                   @click="copyText(scope.row.orderInfo.payUrl.wxpay)">
                {{ scope.row.orderInfo.payUrl.wxpay }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="支付QR(红色云闪付/绿色微信)" width="150" align="center">
            <template #default="scope">
              <!--              {{scope.row.order.order_no}}-->
              <div v-if="scope.row.orderInfo.payUrl" :id="scope.row.orderInfo.orderNo"
                   @click="bigQr(scope.row.orderInfo.payUrl)"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--    <el-dialog v-model="a">-->
    <!--      <vue-qr  text="http://11654685" :size="500"></vue-qr>-->
    <!--    </el-dialog>-->
  </el-card>
  <el-dialog v-model="isBig" width="80vw" height="80vh" align-center="true">
    <el-form>
      <el-form-item>
        <div id="bigQr"
             style="height: 30vh;display: flex;justify-content: center;align-items: center;flex-direction: column"></div>
      </el-form-item>

      <el-form-item style="margin-top: 10vh">
        <el-button type="success" @click="bigQr(this.nowBigQrUrls,'wxpay')">微信支付(绿码)</el-button>
        <el-button type="danger" @click="bigQr(this.nowBigQrUrls,'ysf')">云闪付(红码)</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import {ElMessageBox} from "element-plus";
import {ElMessage} from 'element-plus'
import SearchGoods from "@/components/SearchGoods.vue";
import PushMsg from "@/components/PushMsg.vue";
import {
  deleteSnappedSuccess,
  DownLoadSnappedRecord,
  GetOrderInfo,
  getSnappedSuccess,
  SnappedRecordToExcel, upDataPayUrl
} from "@/API/api";
import router from "@/router";

const QRCode = require("qrcodejs2");


export default {
  components: {PushMsg, SearchGoods},
  data() {
    return {
      input: '',
      word: '',
      phone: '',
      isBig: false,
      downLoading: false,
      tableData: [],
      isLoading: false,
      a: true,
      shortcuts: [
        {
          text: '最近1分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 1);
            return [start, end]
          }
        },
        {
          text: '最近5分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 5);
            return [start, end]
          }
        },
        {
          text: '最近10分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 10);
            return [start, end]
          }
        }, {
          text: '最近30分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 30);
            return [start, end]
          }
        }, {
          text: '最近1小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60);
            return [start, end]
          }
        }, {
          text: '最近10小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 10);
            return [start, end]
          }
        }, {
          text: '最近24小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24);

            return [start, end]
          }
        }, {
          text: '最近3天',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 3);

            return [start, end]
          }
        }, {
          text: '最近7天',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 7);

            return [start, end]
          }
        },
      ],
      timeSelect: [],
      nowBigQrUrls: {},
    }
  },
  methods: {
    bigQr(payUrls, type) {
      this.nowBigQrUrls = payUrls
      if (!type) {
        if (payUrls.ysf) {
          type = 'ysf'
        } else {
          type = 'wxpay'
        }
      } else {
        if (type === 'ysf' && !payUrls.ysf) {
          ElMessage.warning('云闪付支付链接未生成')
          return;
        }
      }
      if (!payUrls.ysf && !payUrls.wxpay) {
        ElMessage.warning('支付链接未生成')
        return
      }
      let ele = document.getElementById('bigQr')
      ele ? ele.innerHTML = '' : null
      this.isBig = true

      if (type === 'ysf') {
        this.$nextTick(() => {
          let url = null
          let color = '#F6443F'
          url = payUrls.ysf
          new QRCode('bigQr', {
            // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
            text: url,
            width: 250,
            height: 250,
            colorDark: color,
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        })
      } else {
        this.$nextTick(() => {
          let url = payUrls.wxpay_qr
          let color = '#26D86F'
          new QRCode('bigQr', {
            // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
            text: url,
            width: 250,
            height: 250,
            colorDark: color,
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        })
      }


    },
    router() {
      return router
    },
    copyText(value) {
      navigator.clipboard.writeText(value).then(() => {
        ElMessage.success('复制成功')
      }).catch(() => {
        ElMessage.warning('复制失败')

      });

    },
    updatePayUrl(orderInfo) {
      this.$api.upDataPayUrl(orderInfo).then(rep => {
        if (rep.success) {
          console.log(rep.data)
        }
      })
    },
    fuzzySearchClick() {
      let start_time = null
      let end_time = null
      if (this.timeSelect && this.timeSelect.length === 2) {
        start_time = this.timeSelect[0]
        end_time = this.timeSelect[1]
        start_time = start_time ? parseInt(Date.parse(start_time) / 1000) : null
        end_time = start_time ? parseInt(Date.parse(end_time) / 1000) : null
      }

      this.getSnappedSuccess({word: this.word, phone: this.phone, start_time, end_time}).then(resp => {
        console.log(resp, "resp:::");
        if (resp.data.code === this.$CODE.SUCCESS) {
          if (resp.data.data.length === 0) {
            // 检索到用户
            ElMessage.warning('未找到任务')
          } else {
            // 未检索到用户


          }
        } else {
          ElMessage.warning('未找到任务')
        }

      })
    },
    getSnappedSuccess(params) {
      this.tableData.forEach(item => {
        if (item.orderInfo && item.orderInfo.orderNo) {
          let ele = document.getElementById(item.orderInfo.orderNo)
          ele ? ele.innerHTML = '' : null
        }
      })


      this.isLoading = true
      return this.$api.getSnappedSuccess(params).then(resp => {
        this.tableData = resp.data.data
        this.tableData.forEach(item => {
          this.$nextTick(() => {
            let url = null
            let color = '#F6443F'
            console.log(item)
            if (item.orderInfo.payUrl.ysf) {
              url = item.orderInfo.payUrl.ysf
            } else {
              url = item.orderInfo.payUrl.wxpay_qr
              color = '#26D86F'
            }
            if ([1, 2].includes(item.orderInfo.status)) {
              let ele = document.getElementById(item.orderInfo.orderNo)
              ele ? ele.innerHTML = '取消/已支付不显示二维码' : null
              return
            }
            new QRCode(item.orderInfo.orderNo, {
              // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
              text: url,
              width: 128,
              height: 128,
              colorDark: color,
              colorLight: "#fff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          })
        })
        return resp
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    pay2Qr(pay_url) {
      // ElMessageBox.alert('该方法为高级版专属!')
      // var qrcode = new QRCode(this.$refs.qrCodeUrl, {
      //   text: pay_url, // 需要转换为二维码的内容
      //   width: 100,
      //   height: 100,
      //   colorDark: '#000000',
      //   colorLight: '#ffffff',
      //   correctLevel: QRCode.CorrectLevel.H
      // })
    },
    deleteData(ordNos) {
      this.$api.deleteSnappedSuccess({"orders_no": ordNos}).then(rep => {
        if (rep.data.success) {
          this.tableData = this.tableData.filter(item => !ordNos.includes(item.orderInfo.orderNo))
        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      })

    },
    deleteAllData() {
      let deleteOrders = []
      for (let order of this.tableData) {
        deleteOrders.push(order.orderInfo.orderNo)
      }
      this.deleteData(deleteOrders)
      this.getSnappedSuccess()
    },
    GetOrderInfo(order) {
      let order_no = order.orderInfo.orderNo
      let wxid = order.userInfo.wxid
      this.$api.GetOrderInfo({"order_no": order_no, "wxid": wxid,}).then(rep => {
        if (rep.data.success) {
          let data = rep.data.data.wait_pay_order
          let orderStore = data.sg_order_list[0].store_name
          let goods = data.sg_order_list[0].goods_list[0]
          let orderGoods = goods.name
          let orderGoodsCount = goods.order_num
          let orderGoodsPrice = data.sg_order_list[0].total_price / 100
          let OrderStatus = data.sg_source_order.status === 1 ? '待支付' : "已取消!"
          ElMessageBox.alert(
              '当前订单状态:<br>' + `<div style="color: red">${OrderStatus}</div>` + `<div>店铺:${orderStore}</div>` + `<div>商品:${orderGoods}</div>` + `<div> 数量:${orderGoodsCount} 总价格:${orderGoodsPrice}</div>`,
              '用户:' + ordNo.user.phone + "订单-" + order_no,
              {"dangerouslyUseHTMLString": true,}
          )


        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      })
    },
    fuzzyDataToExcel() {
      this.isLoading = true
      this.$api.DownLoadSnappedRecord().finally(() => {
        this.isLoading = false
      })
    }

  },
  mounted() {
    this.getSnappedSuccess()
  }
}


</script>

<style scoped>

</style>
