<template>

  <div style="height: 100vh;display: flex;flex-direction:column;align-items: center;">
    <div style="height:20%;width: 100vw;margin-bottom: 8vh;margin-top: 10vh">
      <span style="font-size: 3em">{{ title }}</span>
    </div>
    <div style="width: 80%">
      <el-form label-width="80px">
        <el-alert type="warning" show-icon :closable="false" style="margin-bottom: 3vh">
          <p>{{ tips }}</p>
        </el-alert>
        <el-space fill></el-space>
        <el-form-item label="手机号">
          <el-input v-model="phone" placeholder="请输入报备手机号上号"
                    style="width: 100%;"/>
        </el-form-item>
        <el-form-item label="上号城市">
          <el-select v-model="city" placeholder="请选择城市" style="width: 100%;" filterable>
            <el-option
                v-for="item in cities"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送UID/URL" :required="false">
          <el-input v-model="push_url" placeholder="请输入推送URL/WxPusherUID"
                    style="width: 100%;">
            <template #append>
              <el-button type="info" @click="toPushHelp">获取UID</el-button>
            </template>
          </el-input>


        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="dialogVisible_addPhone=true" v-if="canAddPhone" color="#214283">
            自助报备手机号
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="getQR" type="primary" style="margin-top: 2%" :loading="isLoading">获取安卓Pad上号二维码
          </el-button>
        </el-form-item>
        <!--        <el-form-item>-->
        <!--          <el-button @click="login62" type="warning" style="margin-top: 2%" :loading="isLoading">二次唤醒掉线账号-->
        <!--          </el-button>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button @click="$router.push({'name':'OneUser'})" type="danger" style="margin-top: 2%"
                     :loading="isLoading">唤醒登录/查询在线状态/查单/下号
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title="请尽快扫码上号"
        width="60%"
        @close="closeDialogVisible"
        :close-on-click-modal="false"
    >
      <span>请扫码登录</span>
      <template #footer>
        <div style="height: 30vh;display: flex;justify-content: center;align-items: center;flex-direction: column">
          <div style="max-height: 70%" v-loading="Qrloading">
            <img :src="base64Img" style="width: auto;max-height: 100%;">
          </div>
          <div style="text-align: center;color: red;width: 100%;margin-top: 3vh">剩余扫码时长 {{
              leftoverTime
            }}s {{ tipsText }}
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible_addPhone" width="80%">
      <el-form v-model="addPhoneForm">
        <el-form-item label="请输入报备key">
          <el-input type="text" v-model="addPhoneForm.key" placeholder="输入报备key"></el-input>
        </el-form-item>
        <el-form-item label="请输入报备手机号">
          <el-input type="text" v-model="addPhoneForm.phone" placeholder="输入报备手机号"></el-input>
        </el-form-item>
        <el-form-item label="请输入报备手机号">
          <el-button @click="addMyPhone" type="warning">添加自助报备</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        v-model="dialogVisible_TwoLogin"
        @close="dialogVisible_TwoLogin=false"
        v-loading="this.twoLoginData.loading"
        title="唤醒登录"
        width="70%"
    >
      <el-input placeholder="请输入上号手机号" v-model="twoLoginData.phone"></el-input>
      <el-row style="color: red">{{ twoLoginData.tipsText }}</el-row>
      <el-button type="primary" @click="twoLogin" v-loading="twoLoginData.loading">唤醒登录</el-button>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogVisible_TwoLogin = false">Cancel</el-button>
              </span>
      </template>
    </el-dialog>
    <!--    <div style="height:50%;display: flex;flex-direction: column;justify-content: center;align-items: center">-->
    <!--      <el-row style="width: 100%;">-->
    <!--        <el-col>-->
    <!--          <el-input v-model="phone" placeholder="请输入内部口令上号"-->
    <!--                    style="width: 80%;"/>-->
    <!--        </el-col>-->

    <!--      </el-row>-->
    <!--      <el-row style="width: 100%;">-->

    <!--        <el-select v-model="city" filterable placeholder="请选择城市" style="width: 80%;">-->
    <!--          <el-option-->
    <!--              v-for="item in cities"-->
    <!--              :key="item"-->
    <!--              :label="item"-->
    <!--              :value="item">-->
    <!--          </el-option>-->
    <!--        </el-select>-->
    <!--      </el-row>-->

    <!--      <el-row style="width: 100%;">-->
    <!--        <el-col>-->
    <!--          <el-input v-model="push_url" placeholder="请输入息知推送URL"-->
    <!--                    style="width: 80%;"/>-->
    <!--        </el-col>-->

    <!--      </el-row>-->

    <!--      <el-button @click="getQR" type="primary" style="margin-top: 2%" :loading="isLoading">获取iPad上号二维码-->
    <!--      </el-button>-->
    <!--      <el-button @click="login62" type="warning" style="margin-top: 2%" :loading="isLoading">二次唤醒掉线账号-->
    <!--      </el-button>-->
    <!--      <el-dialog-->
    <!--          v-model="dialogVisible"-->
    <!--          title="请尽快扫码上号"-->
    <!--          width="60%"-->
    <!--          @close="closeDialogVisible"-->
    <!--          :close-on-click-modal="false"-->
    <!--      >-->
    <!--        <span>请扫码登录</span>-->
    <!--        <template #footer>-->
    <!--          <div style="height: 30vh;display: flex;justify-content: center;align-items: center;flex-direction: column">-->
    <!--            <div style="max-height: 70%" v-loading="Qrloading">-->
    <!--              <img :src="base64Img" style="width: auto;max-height: 100%;">-->
    <!--            </div>-->
    <!--            <div style="text-align: center;color: red;width: 100%;margin-top: 3vh">剩余扫码时长 {{-->
    <!--                leftoverTime-->
    <!--              }}s {{ tipsText }}-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </el-dialog>-->


    <!--      <el-dialog-->
    <!--          v-model="dialogVisible_TwoLogin"-->
    <!--          @close="dialogVisible_TwoLogin=false"-->
    <!--          v-loading="this.twoLoginData.loading"-->
    <!--          title="唤醒登录"-->
    <!--          width="30%"-->
    <!--      >-->
    <!--        <el-input placeholder="请输入上号手机号" v-model="twoLoginData.phone"></el-input>-->
    <!--        <el-row style="color: red">{{ twoLoginData.tipsText }}</el-row>-->
    <!--        <el-button type="primary" @click="twoLogin" v-loading="twoLoginData.loading">唤醒登录</el-button>-->
    <!--        <template #footer>-->
    <!--      <span class="dialog-footer">-->
    <!--        <el-button @click="dialogVisible_TwoLogin = false">Cancel</el-button>-->
    <!--      </span>-->
    <!--        </template>-->
    <!--      </el-dialog>-->
    <!--    </div>-->
  </div>

</template>

<script>
import {AddPhone, getProxyCity, getPushHelpUrl, getQR, twoLogin} from "@/API/api";
import {ElMessage, ElMessageBox} from "element-plus";
import router from "@/router";

export default {
  name: 'HelloWorld',
  props: {},
  data() {
    return {
      msg: '',
      pushHelpUrl: '',
      base64Img: "",
      dialogVisible: false,
      handleClose: false,
      leftoverTime: 0,
      uuid: '',
      push_url: '',
      deviceId: '',
      phone: '',
      isLoading: false,
      login: false,
      Qrloading: false,
      checkTime: 2000,
      timer: null,
      secondTimer: null,
      tipsText: '',
      dialogVisible_TwoLogin: false,
      wxid: '',
      city: '',
      title: '',
      tips: '',
      cities: [],
      proxy: '',
      proxy_username: '',
      proxy_password: '',
      twoLoginData: {
        loading: false,
        tipsText: "",
        phone: '',
        uuid: '',
      },
      canAddPhone: false,
      dialogVisible_addPhone: false,
      addPhoneForm: {
        key: "",
        phone: ""
      }

    }

  },
  methods: {
    router() {
      return router
    },
    addMyPhone() {
      this.$api.fastAddPhone(this.addPhoneForm).then(rep => {

      })
    },
    getQR() {
      this.isLoading = true
      this.tipsText = ''

      if (!this.checkPushUrl()) {
        return
      }
      // this.$api.getQR({phone: this.phone,proxy:this.proxy,proxy_username:this.proxy_username,proxy_password:this.proxy_password}).then(rep => {
      this.$api.getQR({phone: this.phone, city: this.city,}).then(rep => {
        if (rep.data.code === this.$CODE.SUCCESS) {
          this.Qrloading = false
          this.leftoverTime = 280
          if (this.secondTimer === null) {
            console.log(123)
            this.secondTimer = setInterval(() => {
              this.leftoverTime--
            }, 1000)
          }
          this.base64Img = rep.data.data.img
          this.uuid = rep.data.data.uuid
          this.dialogVisible = true
          this.checkQR()
          this.login = false
          this.timer = setInterval(() => {


            if (this.login) {
              this.closeDialogVisible()
              return
            }
            if (this.leftoverTime < 0) {
              this.closeDialogVisible()
              ElMessageBox.alert('二维码过期,请重新获取二维码', '提醒', {
                confirmButtonText: '确认',
              })
              return;
            }
          }, this.checkTime);
        } else {
          ElMessageBox.alert(rep.data.msg, '提醒', {
            confirmButtonText: '确认',
          })
          this.closeDialogVisible()
        }

      }).catch(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    checkPushUrl() {
      // if(!(this.push_url.startsWith('http')&&this.push_url.concat('xizhi'))){
      //   ElMessageBox.alert('息知推送url格式错误!', {
      //     confirmButtonText: '确认',
      //     dangerouslyUseHTMLString: true
      //   })
      //   this.isLoading = false
      //   return false
      // }
      return true
    },
    checkQR() {
      this.$api.checkQR({
        "uuid": this.uuid,
        "phone": this.phone,
        "push_url": this.push_url,
        "group_wxid": this.$route.query.group_wxid ? this.$route.query.group_wxid : null
      }).then(rep => {
        if (!this.checkPushUrl) {
          return;
        }
        if (!this.dialogVisible) {
          return
        }
        if (rep.data.code === this.$CODE.SUCCESS && rep.data.data.login_status === 1) {

          this.leftoverTime = true
          let userInfo = rep.data.data.user_info
          let userInfoText = '用户：' + userInfo.nick_name + '<br/>WXID:' + userInfo.wxid + '<br/>请保持IPAD在线!!<br/>'
          ElMessageBox.alert('登录成功:<br/>个人信息：<br/>' + userInfoText, '提醒', {
            confirmButtonText: '确认',
            dangerouslyUseHTMLString: true
          })
          this.login = true
          this.closeDialogVisible()
          return;
        } else {

          try {
            if (rep.data.data.login_status === 2) {
              this.tipsText = `检测到用户扫码,你好:${rep.data.data.user_info.nick_name}`
            }
          } catch (e) {

          } finally {
            setTimeout(() => {
              this.checkQR()
            }, 2000)
          }


        }
      }).catch(() => {
        setTimeout(() => {
          this.checkQR()
        }, 2000)
      }).finally(() => {
        this.isLoading = false
      })
    },
    closeDialogVisible() {
      this.dialogVisible = false
      this.isLoading = false
      this.Qrloading = true

      this.timer ? clearInterval(this.timer) : null
    },
    login62() {
      this.dialogVisible_TwoLogin = true
    },
    twoLogin() {

      if (!this.phone) {
        ElMessageBox.alert('手机号未填写!')
        return
      }

      this.$api.twoLogin({phone: this.phone}).then(rep => {
        try {
          this.twoLoginData.loading = true
          if (rep.data.code === this.$CODE.SUCCESS) {
            this.twoLoginData.uuid = rep.data.data.uuid
            this.checkTwoLogin()
          } else {
            setTimeout(() => {
              this.twoLoginData.loading = false
            }, 1000)
            this.twoLoginData.tipsText = rep.data.msg
          }
        } catch (e) {
          setTimeout(() => {
            this.twoLoginData.loading = false
          }, 1000)
        }

      }).catch(() => {
        setTimeout(() => {
          this.twoLoginData.loading = false
        }, 1000)
      })
    },
    toPushHelp() {
      //
      if (this.pushHelpUrl.includes('api/qrcode')) {
        ElMessageBox.alert(`<img src="${this.pushHelpUrl}" alt="" style="width: 50%;height: auto">`, '请扫码关注并点击公众号右下角获取UID', {
          dangerouslyUseHTMLString: true,
        })
        return
      } else {
        let isWeixin = navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1;
        if (isWeixin) {
          location.href = this.pushHelpUrl

        } else {
          ElMessageBox.alert('当前非微信打开,请复制下方链接到微信打开!\n' + this.pushHelpUrl)
        }
      }


    },
    getPushHelpUrl() {
      this.$api.getPushHelpUrl().then(rep => {
        this.pushHelpUrl = rep.data.data.url
      })
    },
    checkTwoLogin() {

      this.$api.checkTwoLogin({"uuid": this.twoLoginData.uuid}).then(rep => {
        if (!this.dialogVisible_TwoLogin) {
          return
        }
        if (rep.data.code === this.$CODE.SUCCESS && rep.data.data.login_status === 1) {
          this.dialogVisible_TwoLogin = false

          this.twoLoginData.tipsText = '登录成功: '
          let userInfo = rep.data.data.user_info
          let userInfoText = '用户：' + userInfo.nick_name + '<br/>WXID:' + userInfo.wxid + '<br/>请保持IPAD在线!!<br/>'

          ElMessageBox.alert('登录成功:<br/>个人信息：<br/>' + userInfoText, '提醒', {
            confirmButtonText: '确认',
            dangerouslyUseHTMLString: true
          })
          setTimeout(() => {
            this.twoLoginData.loading = false
          }, 1000)
        } else {
          try {
            this.twoLoginData.tipsText = rep.data.msg
          } catch (e) {

          } finally {
            setTimeout(() => {
              this.checkTwoLogin()
            }, 2000)
          }


        }
      }).catch(() => {
        setTimeout(() => {
          this.checkTwoLogin()
        }, 2000)
      })
    },
    getProxyCity() {
      this.$api.getProxyCity().then(rep => {
        this.cities = rep.data.data.cities
      })
    }
  },
  beforeMount() {

    this.getPushHelpUrl()
    this.$api.getBanner().then(rep => {
      if (rep.data.success) {
        this.title = rep.data.data.title
        this.tips = rep.data.data.tips
      } else {
        this.title = 'Pad协议上号!'
        this.tips = '请保持Pad在线'
      }
    }).catch(() => {
      this.title = 'Pad协议上号!'
      this.tips = '请保持Pad在线'
    })
    this.getProxyCity()
    this.$api.canAddPhone().then(rep => {
      if (rep.data.success) {
        this.canAddPhone = rep.data.data.open
      }
    })
  },
  mounted() {
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'viewport');
      meta.setAttribute('content', 'width=device-width, initial-scale=1.0');
      document.head.appendChild(meta);
    setTimeout(() => {
      this.$watermark.remove()
    }, 500)
  },
  beforeUnmount() {
    this.$watermark.create();
  }
}
</script>

<style lang="less">
body{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html{
  overflow: hidden;
}
</style>
