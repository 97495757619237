<template>
  <el-button
      size="small"
      type="primary"
      @click="centerDialogVisible=true"
  >
    一键推送
  </el-button>
  <el-dialog v-model="centerDialogVisible" :modal="true" title="息知推送" width="30%" center>
    <el-row class="row">
      <el-col :span="3">
      </el-col>
      <el-col :span="18">
        <el-input placeholder="请输入推送标题" v-model="pushData.title"></el-input>
      </el-col>
      <el-col :span="3">
      </el-col>
    </el-row>
    <el-row class="row">
      <el-col :span="3">
      </el-col>
      <el-col :span="18">
        <el-input placeholder="请输入推送内容" v-model="pushData.content"></el-input>
      </el-col>

      <el-col :span="3">
      </el-col>
    </el-row>
    <el-row class="row">
      <el-col :span="3">
      </el-col>
      <el-col :span="18">
        <el-input placeholder="请输入推送Url" v-model="push_url"></el-input>
      </el-col>

      <el-col :span="3">
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="pushMsg">
          确认推送
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "PushMsg",
  data() {
    return {
      centerDialogVisible: false,
      push_url: this.url,
      pushData: {
        title: "",
        content: "",
      }
    }
  },
  props: {
    url:{
      type:String,
      required:false,
      default:''
    },
  },
  watch:{
    url(newValue){
      this.push_url = newValue
    }
  },
  methods:{

    pushMsg(){
      if(!this.push_url){
        ElMessage.error('无推送UID/推送链接')
      }
      this.$api.pushMsg(this.push_url,{url:this.push_url,title:this.pushData.title,content:this.pushData.content})
    }
  }
}
</script>

<style scoped>
.row {
  margin-bottom: 1vh;
}

</style>