<template>
  <el-card shadow="hover">
    <div style="padding: 24px;">
      <div style="width: 100%;margin-bottom: 24px;">
        <el-form>
          <el-form-item>
            <el-input v-model="word" placeholder="请输入关键词模糊搜索"/>

          </el-form-item>
          <el-form-item>
            <el-button @click="fuzzySearchClick" type="primary" :loading="isLoading">搜索</el-button>

          </el-form-item>
        </el-form>
        <el-form class="button-container">
          <el-button @click="addPhoneData.dialogVisible=true" type="info">批量添加手机</el-button>
          <el-button @click="addProxyData.dialogVisible=true" color="#FE4E75">批量添加IP代理</el-button>
          <el-button @click="getUser" type="primary" :loading="isLoading">刷新表格</el-button>
          <el-button @click="updateAllUserInfo" type="warning" :loading="isLoading">一键更新信息</el-button>
          <el-button @click="group_wxid_dialog=true" type="warning" color="#BD4AE8">分组登录链接</el-button>
          <el-button @click="proxyForm.dialog=true" color="#151B2B" :loading="isLoading">批量设置代理</el-button>
          <el-button @click="A16Form.dialog=true" type="danger" :loading="isLoading">A16/62登录</el-button>
          <el-button @click="ExcelToUserInfo" color="#4A446E" :loading="isLoading">一键导出用户数据</el-button>
          <el-button @click="addressForm.dialog=true" color="#008000" :loading="isLoading">批量设置收货地址</el-button>
          <el-button @click="TwoLoginAndCheckAll" color="#123C99" :loading="isLoading">不在线账号登录一键推送登录
          </el-button>


        </el-form>

      </div>
      <div style="width: 100%;">
        <el-table :data="tableData" style="width: 100%" border :cell-style="{textAlign:'center'}"
                  :header-cell-style="{textAlign:'center'}" v-loading="isLoading">
          <el-table-column label="ID" width="50" align="center">
            <template #default="scope">
              <div>
                <span>{{ scope.row.wechat_info.id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="微信昵称">
            <template #default="scope">
              <div>
                <span>{{ scope.row.wechat_info.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="微信ID" width="180">
            <template #default="scope">
              <div>
                <span>{{ scope.row.wechat_info.wxid }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手机号" width="120">
            <template #default="scope">
              <div>
                <span>{{ scope.row.wechat_info.phone }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="归属组" width="120">
            <template #default="scope">
              <div>
                <span>{{ scope.row.wechat_info.group_wxid }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="泡泡玛特OpenID" :show-overflow-tooltip="true" width="250">
            <template #default="scope">
              <div>
                <!--                <span>{{ scope.row.popmart_info.open_id.slice(0,5)+'***'+scope.row.wechat_info.phone.slice(-5) }}</span>-->
                <span>{{ scope.row.popmart_info.open_id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收货地址">
            <template #default="scope">
              <div>
                <!--                <span>{{ scope.row.popmart_info.address.text + scope.row.popmart_info.address.name }}</span>-->
                <span>{{
                    scope.row.popmart_info.address ? (scope.row.popmart_info.address.text + scope.row.popmart_info.address.name) : ""
                  }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上号口令" width="120">
            <template #default="scope">
              <div>
                <!--                <span>{{ scope.row.wechat_info.phone.slice(0,3)+'***'+scope.row.wechat_info.phone.slice(-3) }}</span>-->
                <span>{{ scope.row.wechat_info.phone }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上号时间">
            <template #default="scope">
              <span>{{ scope.row.wechat_info.login_time }}</span>
            </template>
          </el-table-column>
          <el-table-column label="掉线时间">
            <template #default="scope">
              <span :style="{color:scope.row.wechat_info.online?'green':'red'}">{{
                  scope.row.wechat_info.online ? '在线中...' : scope.row.wechat_info.end_time
                }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" @click="PopMartFunctionUpdatePopMart(scope.row)"
                         v-loading="loadingBtn[scope.row.wechat_info.wxid]">
                更新信息
              </el-button>
              <el-button
                  v-loading="loadingBtn[scope.row.wechat_info.wxid]"
                  size="small"
                  type="danger"
                  @click="PopMartFunctionDeletePopMartUser(scope.row)"
              >
                删除用户
              </el-button>
              <el-button
                  v-loading="loadingBtn[scope.row.wechat_info.wxid]"
                  size="small"
                  type="danger"
                  @click="SetProxy(scope.row)"
              >
                清除代理IP
              </el-button>
              <el-button
                  v-loading="loadingBtn[scope.row.wechat_info.wxid]"
                  size="small"
                  type="warning"
                  @click="TwoLoginAndCheck(scope.row.wechat_info.phone)"
              >
                一键推送登录
              </el-button>
              <push-msg :url="scope.row.wechat_info.push_url"></push-msg>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </el-card>
  <el-dialog
      title="批量添加手机号"
      v-model="addPhoneData.dialogVisible"
      width="90%">
    <el-form>
      <el-form-item label="备注信息">
        <el-input
            type="text"
            placeholder="请输入添加手机号备注"
            v-model="addPhoneData.tips">
        </el-input>
      </el-form-item>
      <el-form-item label="多久后过期/分钟">
        <el-input-number v-model="addPhoneData.minutes" :min="60*24" :max="60000000000" :step="60"
                         label="请输入多久后过期/分钟"></el-input-number>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            placeholder="请输入手机号,按行分隔,每个手机号最少6位!"
            v-model="addPhoneData.PhoneText">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="addPhoneData.dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addManyPhone">确定添加</el-button>
  </span>
  </el-dialog>
  <el-dialog v-model="A16Form.dialog" width="95%">
    <el-form>
      <el-form-item label="62/A16数据填写">
        <el-input style="white-space: pre-wrap;word-break: break-all;" type="textarea" :rows="20"
                  v-model="A16Form.loginText"
                  placeholder="请按照格式输入: wxid----密码----62/A16数据 将自动识别62/A16数据">请输入62/A16数据
        </el-input>
      </el-form-item>
      <el-form-item label="代理IP填写">
        <el-input style="white-space: pre-wrap;word-break: break-all;" type="textarea" :rows="20"
                  v-model="A16Form.proxyText"
                  placeholder="请按照格式输入: IP:端口----代理账号----代理密码">请输入代理IP
        </el-input>
      </el-form-item>
      <el-form-item label="请输入归属WXID">
        <el-input style="white-space: pre-wrap;word-break: break-all;" type="text" v-model="A16Form.groupWxid"
                  placeholder="请输入归属WXID">请输入归属WXID
        </el-input>
      </el-form-item>
      <el-form-item label="提交登录">
        <el-button @click="Many62Login" v-loading="A16Form.loading">登录账号</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="proxyForm.dialog" width="95%">
    <el-form>
      <el-form-item label="wxid及代理数据填写">
        <el-input style="white-space: pre-wrap;word-break: break-all;" type="textarea" :rows="20"
                  v-model="proxyForm.proxyText"
                  placeholder="请按照格式输入: wxid----代理IP:端口----代理账号----代理密码 将自动识别">请输入代理信息
        </el-input>
      </el-form-item>
      <el-form-item label="提交修改">
        <el-button @click="ManysettingProxy" v-loading="proxyForm.loading">一键修改代理</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="addProxyData.dialogVisible" width="95%">
    <el-form>
      <el-form-item label="批量添加代理IP">
        <el-input style="white-space: pre-wrap;word-break: break-all;" type="textarea" :rows="20"
                  v-model="addProxyData.proxyText"
                  placeholder="请按照格式输入: 代理IP:端口----代理账号----代理密码----城市名 将自动识别">请输入代理信息
        </el-input>
      </el-form-item>
      <el-form-item label="代理过期时间">
        <el-date-picker placeholder="请选择代理过期时间" type="datetime" v-model="addProxyData.endTime"
                        :shortcuts="addProxyData.shortcuts">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提交修改">
        <el-button @click="AddManyProxy" v-loading="addProxyData.loading">一键添加</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="addressForm.dialog" width="95%" v-loading="addressForm.loading">
    <!--    <el-form v-model="addressForm">-->
    <!--      <el-form-item label="批量设置收货地址">-->
    <!--        <el-input type="textaera" placeholder="请按行分隔输入wxid"></el-input>-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->

    <el-form :model="addressForm.addressInfo" label-width="80px">
      <el-form-item label="需要设置的wxid">
        <el-input type="textarea" :rows="20" v-model="addressForm.wxids" placeholder="请按行分隔输入wxid"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名" prop="receiving_name">
        <el-input v-model="addressForm.addressInfo.receiving_name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="receiving_tel">
        <el-input v-model="addressForm.addressInfo.receiving_tel"></el-input>
      </el-form-item>
      <el-form-item label="省" prop="province">
        <el-input v-model="addressForm.addressInfo.province"></el-input>
      </el-form-item>
      <el-form-item label="市" prop="municipality">
        <el-input v-model="addressForm.addressInfo.municipality"></el-input>
      </el-form-item>
      <el-form-item label="区" prop="district">
        <el-input v-model="addressForm.addressInfo.district"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="addressForm.addressInfo.address"></el-input>
      </el-form-item>
      <el-form-item label="门牌号" prop="doors_no">
        <el-input v-model="addressForm.addressInfo.doors_no"></el-input>
      </el-form-item>
      <el-form-item label="门牌号" prop="doors_no">
        <el-button @click="setManyAddress">开始设置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog v-model="group_wxid_dialog">
    <el-form>
      <el-form-item label="输入组名">
        <el-input type="text" v-model="group_wxid"></el-input>
      </el-form-item>
      <el-form-item label="分组登录链接">
        <el-input type="text" v-model="group_wxid_url" readonly></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="gen_group_url">生成分组登录链接</el-button>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>

import {ElMessageBox} from "element-plus";
import {ElMessage} from 'element-plus'
import SearchGoods from "@/components/SearchGoods.vue";
import PushMsg from "@/components/PushMsg.vue";
import {
  deleteWeChatInfo,
  loginData62ORA16, setAddress,
  setProxy,
  TwoLoginAndCheck,
  updatePopMartInfo,
  UserInfoToExcel
} from "@/API/api";
import {ElLoading} from 'element-plus'

export default {
  components: {PushMsg, SearchGoods},
  data() {
    return {
      ttt: false,
      funcs: [],
      group_wxid: "",
      group_wxid_url: "",
      group_wxid_dialog: false,
      input: '',
      word: '',
      phone: '',
      name: 'User',
      tableData: [
        {
          wechat_info: {
            id: 123,
            name: "微信昵称",
            wxid: "微信ID",
            login_time: "2024-02-12 15:30:24",
            phone: '关联上号口令',
            device_id: '设备ID',
            online: false,
          },
          popmart_info: {
            open_id: "OPENID",
            address: "收货地址",
          },
        },
      ],
      isLoading: false,
      loadingBtn: {},
      addPhoneData: {
        dialogVisible: false,
        phones: [],
        PhoneText: '',
        minutes: 60 * 60 * 24 * 30,
        tips: '',
      },
      addProxyData: {
        dialogVisible: false,
        proxies: [],
        proxyText: '',
        endTime: "",
        tips: '',
        loading: false,
        shortcuts: [
          {
            text: '7天后',
            value: () => {
              const date = new Date()
              date.setDate(date.getDate() + 7)
              return date
            },
          },
          {
            text: '14天后',
            value: () => {
              const date = new Date()
              date.setDate(date.getDate() + 14)
              return date
            },
          },
          {
            text: '30天后',
            value: () => {
              const date = new Date()
              date.setDate(date.getDate() + 30)
              return date
            },
          }
        ]
      },
      A16Form: {
        dialog: false,
        loginText: 'wxid----密码----62/A16数据\nwxid----密码----62/A16数据',
        loading: false,
        groupWxid: '',
        proxyText: 'xx.xx.xx.xx:yy----账号----密码',
      },
      proxyForm: {
        dialog: false,
        proxyText: 'wxid----代理IP及端口----代理账号----代理密码(必须使用socks5代理)',
        loading: false,
      },
      addressForm: {
        dialog: false,
        wxids: '',
        loading: false,
        addressInfo: {
          "receiving_name": "姓名",
          "receiving_tel": "电话",
          "province": "省",
          "municipality": "市",
          "district": "区",
          "address": "详细地址",
          "doors_no": "门牌号",
        },
      },
    }
  },
  methods: {
    parseProxies() {

      // 将文本按行分割成数组
      const lines = this.addProxyData.proxyText.split('\n');
      // 对每一行进行处理
      this.addProxyData.proxies = lines.map(line => {
        const parts = line.split('----');
        if (parts.length === 4) {
          return {
            host: parts[0],
            username: parts[1],
            password: parts[2],
            city: parts[3],
            end_time: new Date(this.addProxyData.endTime).getTime() / 1000
          };
        }
        return null; // 如果分割后不符合预期，返回null
      }).filter(proxy => proxy !== null); // 过滤掉任何null项

    },
    AddManyProxy() {
      this.parseProxies()
      if (!this.addProxyData.endTime) {
        this.$message.warning('请填写过期时间,并保证过期时间大于当前!')
        return
      }
      if (this.addProxyData.proxies.length < 1) {
        this.$messageBox.alert('无符合规则代理!')
        return
      }
      this.$message.info(`${this.addProxyData.proxies.length}个代理,将被添加...`)
      this.addProxyData.loading = true
      this.$api.addManyProxy({proxies: this.addProxyData.proxies}).then(resp => {
        if (!resp.data.success) {
          this.$messageBox.alert(resp.data.msg)
        }
      }).catch(() => {
        this.$messageBox.alert('添加失败,出现错误!')
      }).finally(() => {
        this.addProxyData.loading = false
      })

    },
    gen_group_url() {
      if (!this.canGroup()) {
        ElMessageBox.alert('您不支持分组功能!')
        return
      } else {
        const router = this.$router;
        const resolved = router.resolve({name: 'LoginWeChat', query: {"group_wxid": this.group_wxid}});
        this.group_wxid_url = `${window.location.origin}${resolved.href}`;
        // return fullUrl;
      }
    },
    getFunctions() {
      this.$api.getFuncs().then(resp => {
        if (resp.data.success) {
          this.funcs = resp.data.data
        }
      })
    },
    canA16() {
      return this.funcs.includes('a16')
    },
    canGroup() {
      return this.funcs.includes('group')
    },
    ExcelToUserInfo() {
      this.$api.UserInfoToExcel().then()
    },
    setManyAddress() {
      let submit_datas = []
      for (let line of this.addressForm.wxids.split('\n')) {
        if (!line.trim()) {
          continue
        }
        submit_datas.push({
          wxid: line.trim(),
          address: this.addressForm.addressInfo
        })
      }
      this.addressForm.loading = true
      this.$api.setAddress({datas: submit_datas}).then(rep => {
        if (rep.data.success) {
          this.addressForm.wxids = rep.data.data.text
        }
      }).finally(() => {
        this.addressForm.loading = false
      })
    },
    ManysettingProxy() {
      this.proxyForm.loading = true
      let proxyDatas = this.proxyForm.proxyText.split('\n')
      let submit_datas = []
      for (let line of proxyDatas) {
        line = line.replace(' ', '')
        line = line.replace('：', ":")
        let separate_data = line.trim().split('----')
        if (!separate_data) {
          continue
        }
        let wxid = separate_data[0]
        let proxy = {
          proxyIp: "",
          proxyUserName: "",
          proxyPassword: "",
        }
        if (separate_data.length >= 4) {
          proxy.proxyIp = separate_data[1].trim()
          proxy.proxyUserName = separate_data[2].trim()
          proxy.proxyPassword = separate_data[3].trim()
        }
        let data = {
          wxid: wxid,
          proxy: proxy
        }
        submit_datas.push({
          data: data,
          meta_text: line.trim()
        })
      }
      this.proxyForm.proxyText = ''
      let x = 0
      for (let submit_data of submit_datas) {
        setTimeout(
            () => {
              this.$api.setProxy(submit_data.data).then(rep => {
                if (!rep.data.success) {
                  this.proxyForm.proxyText += submit_data.meta_text + '----' + rep.data.msg + '\n'
                }
              }).catch(err => {
                this.proxyForm.proxyText += submit_data.meta_text + '----服务器出错' + '\n'
              }).finally(() => {
                x += 1
                if (x === submit_datas.length) {
                  this.proxyForm.loading = false
                }
              })
            }, 10
        )

      }

    },
    getRandomElement(arr) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    },
    Many62Login() {
      if (!this.canA16()) {
        ElMessageBox.alert('您不支持A16功能')
        return
      }
      this.A16Form.loading = true
      let A16Datas = this.A16Form.loginText.split('\n')
      let A16proxyText = this.A16Form.proxyText.split('\n')
      localStorage.setItem('proxy', A16proxyText)
      let proxys = []
      for (let proxy of A16proxyText) {
        proxy = proxy.trim()
        let proxylist = proxy.split('----')
        if (proxylist.length >= 3) {
          proxys.push({
            proxyIp: proxylist[0],
            proxyUserName: proxylist[1],
            proxyPassword: proxylist[2],
          })
        } else {
          proxys.push({
            proxyIp: proxylist[0],
            proxyUserName: "",
            proxyPassword: "",
          })
        }


      }
      let submit_datas = []
      for (let line of A16Datas) {
        let separate_data = line.trim().split('----')
        if (separate_data.length >= 3) {
          let data = separate_data[2]
          let submit_data = {
            wxid: separate_data[0],
            password: separate_data[1]
          }
          if (data.startsWith('62')) {
            submit_data['data62'] = data
          } else {
            submit_data['a16'] = data
          }
          submit_data["group_wxid"] = this.A16Form.groupWxid
          submit_data["_data"] = data
          submit_datas.push(submit_data)
        }
      }
      let all_once = submit_datas.length
      let x = 0
      this.A16Form.loginText = ''
      let y = 0
      let useProxy = []
      for (let submit_data of submit_datas) {
        let pro = null
        for (let proxy of proxys) {
          if (useProxy.includes(proxy)) {
            continue
          }
          pro = proxy
          useProxy.push(pro)
          break
        }
        if (pro === null && proxys) {
          pro = this.getRandomElement(proxys)
        }

        submit_data["proxy"] = pro
        setTimeout(
            () => {
              this.$api.loginData62ORA16(submit_data).then(rep => {
                try {
                  if (rep.data.success) {
                    this.A16Form.loginText += submit_data.wxid + rep.data.msg + '\n'
                  } else {
                    this.A16Form.loginText += submit_data.wxid + '----' + submit_data.password + '----' + submit_data._data + '----' + rep.data.msg + '----出错\n'
                  }

                } catch (e) {
                  this.A16Form.loginText += submit_data.wxid + '----' + submit_data.password + '----' + submit_data._data + '----失败!' + '----出错\n'
                }
              }).catch(err => {
                this.A16Form.loginText += submit_data.wxid + '----' + submit_data.password + '----' + submit_data._data + '----失败!' + '----服务器出错\n'
              }).finally(() => {
                x += 1
              })
            }, 300
        )

      }

      let timer = setInterval(() => {
        if (x >= all_once) {
          this.A16Form.loading = false
          clearInterval(timer)
        }
      }, 500)
    },
    addManyPhone() {
      this.phones = this.addPhoneData.PhoneText.split("\n");
      this.$api.AddPhone({
        phone: this.phones,
        tips: this.addPhoneData.tips,
        minutes: this.addPhoneData.minutes
      }).then(resp => {
        if (resp.data.success) {
          if (resp.data.data.fail_phone) {
            this.addPhoneData.PhoneText = resp.data.data.fail_phone.join('\n----添加失败!')
          }
        } else {
          ElMessageBox.alert(resp.data.msg)
        }
      })
    },
    fuzzySearchClick() {

      this.getUser({word: this.word,}).then(resp => {
        if (resp.data.code === this.$CODE.SUCCESS) {
          if (resp.data.data.length === 0) {
            // 检索到用户
            ElMessageBox.alert('未找到该关键词用户，请检查后重新尝试')
          } else {
            // 未检索到用户
            this.tableData = resp.data.data
            console.log(resp.data)
          }
        } else {
          ElMessageBox.alert('请求失败！')
        }

      })
    },
    getUser(params) {
      this.isLoading = true
      return this.$api.getUser(params).then(resp => {
        this.tableData = resp.data.data
        for (let user of resp.data.data) {
          this.loadingBtn[user.wechat_info.wxid] = false
        }
        return resp
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    PopMartFunctionUpdatePopMart(userInfo) {
      this.loadingBtn[userInfo.wechat_info.wxid] = true
      this.$api.updatePopMartInfo({"wxid": userInfo.wechat_info.wxid}).then(rep => {
        userInfo.popmart_info.address = rep.data.data.address
        userInfo.popmart_info.open_id = rep.data.data.openid
      }).finally(() => {
        this.loadingBtn[userInfo.wechat_info.wxid] = false
      })
    },
    updateAllUserInfo() {

      let loading = ElLoading.service({
        lock: true,
        text: '正在更新用户信息中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$api.updateAllPopMartInfo().then(rep => {

      }).finally(() => {

        setTimeout(() => {
          this.getUser()
        }, 3500)


        setTimeout(() => {
          loading.close()
        }, 4000)
      })
    },
    PopMartFunctionDeletePopMartUser(userInfo) {
      this.loadingBtn[userInfo.wechat_info.wxid] = true
      this.$api.deleteWeChatInfo({"wxid": userInfo.wechat_info.wxid}).then(rep => {
        if (rep.data.success) {
          ElMessageBox.alert('删除成功:' + rep.data.msg)
          this.tableData = this.tableData.filter(item => item.wechat_info.wxid !== userInfo.wechat_info.wxid);
        }
      }).finally(() => {
        this.loadingBtn[userInfo.wechat_info.wxid] = false
      })
    },
    SetProxy(userInfo) {
      this.$api.setProxy({wxid: userInfo.wechat_info.wxid}).then(rep => {

      })
    },
    TwoLoginAndCheck(phone) {
      this.$api.TwoLoginAndCheck({phone: phone}).then(rep => {
      })
    },
    TwoLoginAndCheckAll() {
      let loading = ElLoading.service({
        lock: true,
        text: '正在唤醒登录中,请在120秒内点击所有账号登录,并点击一键更新全部信息......',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      setTimeout(() => {
        loading.close
      }, 5000)

      for (let user of this.tableData) {
        if (!user.wechat_info.online) {
          this.TwoLoginAndCheck(user.wechat_info.phone)
        }
      }

    }
  },
  beforeMount() {
    this.getUser()
    this.getFunctions()
    if (localStorage.getItem('proxy')) {
      this.A16Form.proxyText = localStorage.getItem('proxy')
    }

  }
}

// const PopMartFunction = {
//   updatePopMart: (userId) => {
//     console.log('更新泡泡玛特信息')
//   },
//   deletePopMartUser: (userId) => {
//     console.log('删除泡泡玛特信息')
//   },
//   pushPopMartUser: (userId) => {
//     console.log('推送泡泡玛特信息')
//   }
// }

</script>

<style scoped>
.button-container {
  display: flex;
  flex-wrap: wrap;
}


</style>
