<template>
  <el-card shadow="hover" v-loading="loading">
    <el-form>
      <el-form-item>
        <el-button type="primary" @click="updateAdminPasswordData.dialogVisible =true">修改管理系统账号密码</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="toAdmin">后台管理系统</el-button>
      </el-form-item>
    </el-form>
    <el-form v-model="frontConfig">
      <el-form-item label="单账号请求频率控制">
        <el-input-number v-model="frontConfig.wait_time" :precision="2" :step="0.1" :max="10"
                         :min="0.1"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-input type="text" placeholder="请输入前台显示标题" v-model="frontConfig.title"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input type="text" placeholder="请输入前台显示提示文本" v-model="frontConfig.tips"></el-input>
      </el-form-item>
    </el-form>

    <el-form v-model="aboveConfig">
      <el-form-item label="自助报备密钥">
        <el-input type="text" v-model="aboveConfig.key" placeholder="请输入自助报备密钥"></el-input>
      </el-form-item>
      <el-form-item label="是否开启自助报备">
        <el-switch
            v-model="aboveConfig.open"
            class="ml-2"
        />
      </el-form-item>
      <el-form-item label="免报备上号">
        <el-switch
            v-model="aboveConfig.fast_login"
            class="ml-2"
        />
      </el-form-item>
      <el-form-item label="滑块代理IP-URL">
        <el-input
            placeholder="不填写IP代理,账号过多时可能会导致滑块无法正常处理"
            v-model="aboveConfig.ip_url"
            class="ml-2"
        />
        <a href="http://www.xiongmaodaili.com?invitationCode=0B1887FD-9EA6-4FA6-90CA-E101CCDE34D9">
          <el-button type="danger">购买IP代理--选择包量套餐1000条即可</el-button>
        </a>


      </el-form-item>
      <el-form-item label="全局推送WXID">
        <el-input type="text" v-model="aboveConfig.send_wxid" placeholder="请输入推送人WXID"></el-input>
      </el-form-item>

      <el-form-item label="全局推送接受人/群WXID">
        <el-input type="text" v-model="aboveConfig.to_wxid" placeholder="请输入推送人WXID"></el-input>
      </el-form-item>
      <!--      <el-button>获取群聊/WXID</el-button>-->
      <el-form-item>
        <el-button @click="getChatDig" type="danger">获取群ID信息</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="testSendWeChatMsg" type="warning">测试发送</el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="updateAboveConfig" type="success">确认提交修改配置信息</el-button>
      </el-form-item>
    </el-form>
    <!--    <el-form>-->
    <!--      <el-form-item>-->
    <!--        <el-button @click="DownUserToPy">导出用户信息用于CHJ抢购</el-button>-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->
    <el-form>
      <el-form-item label="更新全国门店信息">
        <el-button type="primary" @click="updateStoreInfo.dialogVisible=true">开始更新</el-button>
      </el-form-item>
    </el-form>

  </el-card>
  <el-card>
    <el-button type="warning" @click="downLoadNewVersion">下载最新版本</el-button>
    <el-popconfirm title="确定进行版本更新?该操作将彻底关闭应用,直至更新完毕,预计更新时间1分钟左右,所有任务都会被取消!"
                   confirm-button-text="确认删除"
                   cancel-button-text="取消" @confirm="startUpdateNewVersion">
      <template #reference>
        <el-button type="danger">覆盖更新</el-button>
      </template>
    </el-popconfirm>
    <el-row>版本信息: {{ version.version }}</el-row>
    <Markdown :source="version.info"></Markdown>
  </el-card>

  <el-dialog
      v-model="updateStoreInfo.dialogVisible"
      title="Tips"
      width="90%"
  >
    <span>警告,该接口必须在无任务情况下调用<br>否则将会对任务造成影响导致死锁!</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="updateStoreInfo.dialogVisible = false" v-loading="loading">取消</el-button>
        <el-button type="danger" @click="updateStore" v-loading="loading">
          确认更新
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="updateAdminPasswordData.dialogVisible" width="90%">
    <el-form>
      <el-form-item label="请输入新的账号">
        <el-input placeholder="请输入账号" :min="4" type="text" v-model="updateAdminPasswordData.username"></el-input>
      </el-form-item>
      <el-form-item label="请输入新的密码">
        <el-input placeholder="请输入密码" :min="6" type="password"
                  v-model="updateAdminPasswordData.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="updateAdminPassword" v-loading="loading">
          确认更新账号密码
        </el-button>
      </el-form-item>
    </el-form>

  </el-dialog>
  <el-dialog v-model="getWeChat.dialogVisible" width="90%">
    <el-form>
      <el-form-item label="输入获取的微信ID">
        <el-input type="text" placeholder="请输入微信ID" v-model="getWeChat.wxid"></el-input>
      </el-form-item>
      <el-form-item label="请先输入右侧字符到要获取ID的微信群">
        <el-input type="text" v-model="getWeChat.randomNum"></el-input>
      </el-form-item>
      <el-form-item label="获取指定群ID">
        <el-button @click="getChatRoom" type="warning" v-load="getWeChat.loading">获取群ID信息</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import {
  DownUserToPy,
  getChatRoomId,
  getFrontConfig,
  getVersionInfo,
  startUpdateNewVersion,
  updateStoreList
} from "@/API/api";
import {ElMessageBox} from "element-plus";
import router from "@/router";
import Markdown from 'vue3-markdown-it';

export default {
  name: "Config",
  components: {Markdown},
  data() {
    return {
      loading: false,
      updateStoreInfo: {
        dialogVisible: false,

      },
      updateAdminPasswordData: {
        dialogVisible: false,
        username: "",
        password: "",
      },
      frontConfig: {
        title: "",
        tips: "",
        wait_time: 0.6,
      },
      aboveConfig: {
        open: false,
        key: "",
        send_wxid: "",
        to_wxid: "",
        ip_url: "",
        fast_login: false
      },
      version: {
        version: '',
        info: '',
      },
      getWeChat: {
        wxid: "",
        to_wxid: "",
        msg: "",
        dialogVisible: false,
        randomNum: '',
        loading: false
      }
    }
  },
  methods: {
    // DownUserToPy(){
    //   this.$api.DownUserToPy().then(rep=>{
    //     console.log(rep)
    //   })
    // },
    toAdmin() {
      location.href = '/admin'
    },
    updateStore() {
      this.loading = true
      this.$api.updateStoreList().then(rep => {
        if (rep.data.success) {
          ElMessageBox.alert('更新完毕!')
        } else {
          ElMessageBox.alert(rep.data.msg)
        }
      }).finally(() => {
        this.updateStoreInfo.dialogVisible = false
        this.loading = false
      })
    },
    updateAboveConfig() {
      this.setFrontConfig()
      this.$api.setAboveKey(this.aboveConfig).then(rep => {
        if (rep.data.success) {
          this.aboveConfig = rep.data.data
        }
        ElMessageBox.alert(rep.data.msg)

      })
    },
    updateAdminPassword() {
      this.$api.updateAdminPassword({...this.updateAdminPasswordData}).then(rep => {
        if (rep.data.success) {
          router.push({name: "LoginAdmin"})
        }
      })
    },
    getAboveConfig() {
      this.$api.getAboveKey().then(rep => {
        if (rep.data.success) {
          this.aboveConfig = rep.data.data
          console.log(rep.data.data)
        }
      })
    },
    getVersion() {
      this.$api.getVersionInfo().then(rep => {
        this.version = rep.data.data
      })
    },
    getFrontConfig() {
      this.$api.getFrontConfig().then(rep => {
        this.frontConfig = rep.data.data
      })
    },
    setFrontConfig() {
      this.$api.setFrontConfig(this.frontConfig).then(rep => {
        this.frontConfig = rep.data.data
      })
    },
    downLoadNewVersion() {
      this.$api.downLoadNewVersion().then(rep => {
        if (!rep.data.success) {
          ElMessageBox.alert(rep.data.msg)
        }

      })
    },
    startUpdateNewVersion() {
      this.$api.startUpdateNewVersion().then(rep => {
        if (!rep.data.success) {
          ElMessageBox.alert(rep.data.msg)
        } else {
          const loading = this.$loading({
            lock: true,
            text: '加载中,等待更新,预计一分钟完成更新!',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          setTimeout(() => {
            loading.close();
          }, 10000);
        }
      })
    },
    getChatDig() {
      let randomNum = '';
      for (let i = 0; i < 20; i++) {
        randomNum += Math.floor(Math.random() * 10);
      }
      this.getWeChat.randomNum = randomNum.toString();
      this.getWeChat.wxid = this.aboveConfig.send_wxid
      this.getWeChat.dialogVisible = true

    },
    getChatRoom() {
      let wxid = this.getWeChat.wxid
      let msg = this.getWeChat.randomNum
      this.getWeChat.loading = true
      this.$api.getChatRoomId({wxid, msg}).then(rep => {
        if (rep.data.success) {
          ElMessageBox.alert('获取群ID成功,请复制保存:' + rep.data.data.wxid, '获取成功')
        }
      }).finally(() => {
        this.getWeChat.loading = true
      })
    },
    testSendWeChatMsg() {
      this.$api.sendWeChatMsg({
        wxid: this.aboveConfig.send_wxid,
        to_wxid: this.aboveConfig.to_wxid,
        msg: "测试发送~"
      }).then(rep => {

      })
    }
  },
  mounted() {
    this.getVersion()
    this.getFrontConfig()
    this.getAboveConfig()
  }
}
</script>

<style scoped>

</style>