const CODE = {
    SUCCESS:1,
    INVALID_ERROR:-6,
    AUTH_ERROR:-1,
    SERVER_ERROR:-5
}

export default CODE


