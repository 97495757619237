import request from '@/API/request'


export const getQR = params => {
    return request.get(`wechat/getQr/`, {params: params})
}

export const twoLogin = params => {
    return request.get(`wechat/twoLogin/`, {params: params})
}


export const checkQR = params => {
    return request.post('wechat/checkQr/', params)
}


export const checkTwoLogin = params => {
    return request.post('wechat/checkTwoLogin/', params)
}


export const getUser = params => {
    return request.get('popmart/getUser/', {params: params})
}

// 获取店铺列表信息
export const getStore = params => {
    return request.get('/popmart/getStore', {params: params})
}

// 搜索商品
export const searchGoods = params => {
    return request.get('/popmart/searchGoods', {params: params})
}

// 搜索商品
export const getTask = params => {
    return request.get('/popmart/getTask', {params: params})
}

// 搜索商品
export const cancelTask = params => {
    return request.post('/popmart/cancelTask', params)
}

// 搜索商品
export const deleteTask = params => {
    return request.post('/popmart/deleteTask', params)
}

// 搜索商品
export const pushMsg = (url, params) => {
    return request.post('/wechat/PushMsg', params)
}
// 搜索商品
export const getPushHelpUrl = (url, params) => {
    return request.get('/wechat/PushHelpUrl', params)
}

// 下单成功记录
export const getSnappedSuccess = params => {
    return request.get('/popmart/orders', {params})
}
export const upDataPayUrl = params => {
    return request.post('/popmart/orders', params)
}

// 下单成功记录
export const startSnappyTask = params => {
    return request.post('/popmart/startSnapped', params)
}

// 下单成功记录
export const startMonitor = params => {
    return request.post('/popmart/startMonitor', params)
}

// 下单成功记录
export const StartExtractStock = params => {
    return request.post('/popmart/StartExtractStock', params)
}

// 下单成功记录
export const startExtractOrderList = params => {
    return request.post('/popmart/startExtractOrderList', params)
}

export const getTaskLog = params => {
    return request.get('/popmart/getTaskLog', {params})
}

export const getProxyCity = params => {
    return request.get('/wechat/proxies', {params})
}

export const updatePopMartInfo = params => {
    return request.get('/popmart/updatePopMartInfo', {params})
}

export const updateAllPopMartInfo = params => {
    return request.get('/popmart/updateAllPopMartInfo', {params})
}


export const deleteWeChatInfo = params => {
    return request.post('/wechat/deleteWeChatUser', params)
}


export const getBanner = params => {
    return request.get('/wechat/getBanner', params)
}


export const deleteSnappedSuccess = params => {
    return request.post('/popmart/deleteSnappedSuccess', params)
}


export const setProxy = params => {
    return request.post('/wechat/SetProxy', params)
}


export const canAddPhone = params => {
    return request.post('/wechat/fastaddPhone', {params})
}
export const fastAddPhone = params => {
    return request.get('/wechat/fastaddPhone', {params})
}

export const getAboveKey = params => {
    return request.get('/wechat/AboveKey', {params})
}

export const setAboveKey = params => {
    return request.post('/wechat/AboveKey', params)
}


export const updateStoreList = params => {
    return request.post('/popmart/UpdateStoreInfo', {
        timeout: 60000 // 设置超时时间为60秒
    })
}


export const setAddress = params => {
    return request.post('/popmart/set_address', params)
}
export const getPopmartUserCache = params => {
    return request.get('/popmart/getPopmartUserCache', {})
}

export const AddPhone = params => {
    return request.post('/wechat/addPhone', params)
}

export const TwoLoginAndCheck = params => {
    return request.get('/wechat/TwoLoginAndCheck', {params})
}

export const searchWordBySkuID = params => {
    return request.get('/popmart/searchWordBySkuID', {params})
}

export const GetOrderInfo = params => {
    return request.get('/popmart/GetOrderInfo', {params})
}


export const OneUserGetUserInfo = params => {
    return request.get('/popmart/oneuser/userInfo', {params})
}


export const OneUserGetOrderList = params => {
    return request.get('/popmart/oneuser/orderList', {params})
}


export const OneUserUpdatePushUrl = params => {
    return request.post('/popmart/oneuser/updatePushUrl', params)
}


export const getWatermark = params => {
    return request.post('/other/watermark', params)
}

export const LoginAdmin = params => {
    return request.post('/other/LoginAdmin', params)
}

export const updateAdminPassword = params => {
    return request.post('/other/updateAdminPassword', params)
}


export const getVersionInfo = params => {
    return request.post('/other/version', params)
}

export const getFrontConfig = params => {
    return request.get('/other/front/getConfig')
}

export const setFrontConfig = params => {
    return request.post('/other/front/setConfig', params)
}

export const startUpdateNewVersion = params => {
    return request.post('/other/startUpdateNewVersion', params)
}

export const downLoadNewVersion = params => {
    return request.post('/other/downLoadNewVersion', params)
}

export const getFuncs = params => {
    return request.post('/other/funcs', params)
}


export const getChatRoomId = params => {
    return request.post('/wechat/SendMsg', params)
}


export const addManyProxy = params => {
    return request.post('/wechat/addProxy', params)
}

export const sendWeChatMsg = params => {
    return request.get('/wechat/SendMsg', {params})
}


export const loginData62ORA16 = params => {
    return request.post('/wechat/62Login', params)
}


import {saveAs} from 'file-saver';

export const DownLoadSnappedRecord = params => {
    return request.get('/popmart/DownLoadSnappedRecord', {
        responseType: 'arraybuffer' // 设置响应类型为二进制数组
    })
        .then(response => {
            let contentDisposition = response.headers['content-disposition'];
            const match = contentDisposition.match(/filename=(.+?)$/);
            let filename
            if (!match) {
                filename = '抢购成功数据下载.xlsx'

            } else {
                filename = match[1];
                filename = decodeURIComponent(filename)
            }

            const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, filename);
        })
        .catch(error => {
            console.error('Error exporting Excel:', error);
        });

}
export const DownUserToPy = params => {
    return request.get('/popmart/userToPy', params)

}

export const UserInfoToExcel = params => {
    return request.get('/popmart/userToExcel', {
        responseType: 'arraybuffer' // 设置响应类型为二进制数组
    })
        .then(response => {
            let contentDisposition = response.headers['content-disposition'];
            const match = contentDisposition.match(/filename=(.+?)$/);
            let filename
            if (!match) {
                filename = '用户导出数据.csv'

            } else {
                filename = match[1];
                filename = decodeURIComponent(filename)
            }

            const blob = new Blob([response.data], {type: 'application/csv'});
            saveAs(blob, filename);
        })
        .catch(error => {
            console.error('Error exporting Excel:', error);
        });

}


export const GUIAPI = {
    logs(params) {
        return request.get('/popmart/gui/logs', params)
    },
    getLog(logName) {
        return request.post('/popmart/gui/operationLog', {type: "get", name: logName})
    },
    deleteLog(logName) {
        return request.post('/popmart/gui/operationLog', {type: "delete", name: logName})
    },

}


export const newTaskBuy = params => {
    return request.post('/popmart/newTask/buy', params)
}
export const newTaskMonitor = params => {
    return request.post('/popmart/newTask/monitor', params)
}
export const newTaskList = params => {
    return request.get('/popmart/newTask/list', {params})
}
export const newTaskDelete = (taskIDs) => {
    return request.post('/popmart/newTask/list', {type: "delete", "tasks": taskIDs})
}
export const newTaskCancel = (taskIDs) => {
    return request.post('/popmart/newTask/list', {type: "cancel", "tasks": taskIDs})
}
export const newTaskLog = (params) => {
    return request.get('/popmart/newTask/log', {params})
}