<template>
  <transition name="route-transition" mode="out-in">
      <router-view></router-view>
  </transition>
</template>

<script>

export default {
  name: "App",
  components: {},
  created() {

  },
  mounted() {
    let texts = ''

    this.$api.getWatermark().then(rep=>{
      if(rep.data.success && rep.data.data.need){
        // 创建全局水印
        let text = rep.data.data.watermark.substring(8,24)
        let text1 = text.slice(0,8)
        let text2 = text.slice(8,16)
        // 退出当前路由前重新创建全局水印, 防止其它页面的水印消失
        texts = [text1,text2]

      }
      this.$watermark.content =texts
      this.$watermark.create();


    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;


}


* {
  margin: 0;
  padding: 0;
}

body{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html{
  overflow: hidden;
}
</style>
