<template>
  <el-container class="home-container">
    <el-aside :width="asideWidth" style="background-color: #151B2B">
      <el-row style="padding: 0 0">
        <el-col>
          <el-menu
              background-color="#151B2B"
              text-color="#fff"
              :default-active="$route.name"
              style="border-right:0"
              active-text-color="#AC30E8"
              :collapse="collapse"
              :collapse-transition="true"
              router
          >
            <div style="text-align: center;color: #E9E9E9;font-size: 5px;height: 20px" @click="changeAside">| | |
            </div>
            <div style="text-align: center">
              <img src="../assets/header.png">
            </div>
            <template v-for="(item,item_index) in menuList">
              <el-menu-item :index="item.path">
                <i :class="item.style"></i>
                <el-icon :size="20" color="hotpink">
                  <component :is="item.style"></component>
                </el-icon>
                <span slot="title">{{ item.authName }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header>
        <el-row class="header" :gutter="24">
          <el-col class="title" :span="22" :offset="1">
            <div style="font-family: YeZiGongChangTangYingHei;font-size: 2.2rem">泡泡玛特抢购管理</div>
          </el-col>
          <el-col :span="2" class="avatar">
            <el-menu mode="horizontal" style="min-height: 0.01vh;height: 0.01vh">
              <el-submenu index="1" style="border: 0">
                <el-menu-item>{{ username }}</el-menu-item>
                <el-menu-item index="2-2" @click="">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>

      </el-header>
      <el-main style="background-color: #F2F2F2">
        <router-view></router-view>
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
export default {
  name: "Navigate",
  data() {
    return {
      username: 'ROOT',
      avatar: '',
      menuList: [
        {
          authName: "账号管理",
          path: "/admin/user",
          style: "User"
        },
        {
          authName: "任务管理",
          path: "/admin/task",
          style: "Discount"
        },
        {
          authName: "抢购|监控管理",
          path: "/admin/newTask",
          style: "Tickets"
        },
        {
          authName: "下单成功管理",
          path: "/admin/buy",
          style: "ShoppingCart"
        },
        {
          authName: "通用配置管理",
          path: "/admin/config",
          style: "Setting"
        },

      ],
      methods: {},
      collapse: false,
      asideWidth: "11vw"
    }
  },
  methods: {
    changeAside() {
      this.collapse = !this.collapse
      this.asideWidth = this.collapse ? '4.5vw' : '11vw'
    },
  },

  mounted() {
  }

}



</script>

<style lang="less" scoped>
img {
  width: 50px;
  margin: 0 auto;
}

.home-container {
  height: 100vh;

  .header {
    display: flex;
    align-items: center; // 垂直居中，针对的是mycontainer类下面的子元素，不包含“孙子”元素
    justify-content: center; // 水平居中，针对的是mycontainer类下面的子元素，不包含“孙子”元素

  }

  .avatar {
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
}


/* 加过渡给侧边导航*/
.el-aside {
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
}

/*加快侧边栏文字消失的速度*/
.el-menu {
  transition: all 10ms;
}

.el-menu.el-menu--horizontal {
  border: none;
}


</style>