import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import {Edit} from '@element-plus/icons-vue' // 引入 Edit 图标
import router from "@/router";
import request from "@/API/request";
import * as api from '@/API/api'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import CODE from "@/API/code";
import VueClipboards from 'vue-clipboard2'

const taskStatusMap = {
    0: "等待中",
    1: "任务开始",
    2: "任务结束",
    3: "任务取消",
}
import { Watermark } from "@/components/watermark"

// 如果需要全局注册

const app = createApp(App)
app.config.globalProperties.$http = request;
app.config.globalProperties.$api = api;
app.config.globalProperties.$taskStatusMap = taskStatusMap;
app.config.globalProperties.$CODE = CODE

app.config.globalProperties.$watermark  = new Watermark({
    content: '全局',
    width: 100,
    height: 80,
    color: 'red',
});
app.component(Edit.name, Edit)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router).use(ElementPlus).mount('#app')
