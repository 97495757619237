import axios from 'axios'
import {ElMessage} from 'element-plus'
import CODE from "@/API/code";
import router from "@/router";

const request = axios.create({
    baseURL: "/api/v1"
})








request.interceptors.request.use(config => {
        // 在请求发送前，检查本地缓存中是否存在账号密码
        // console.log('un',username,'ps',password)
        if(config.url.startsWith('https://xizhi.qqoq.net')){
            return config
        }
        config.headers["888"] = window.sessionStorage.getItem('username');
        config.headers["999"] = window.sessionStorage.getItem('password');
        config.headers['54666666986'] = '132465';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);






request.interceptors.response.use(resp => {

    if (resp.config.url.startsWith('http')) {
        let type = (resp.data.code === 200) ? "success" : "error"
        ElMessage({
            message: resp.data.msg,
            type: type,
        })
        return resp
    } else {
        if(!resp.data.success)
        {
            ElMessage({
                message: resp.data.msg,
                type: 'error',
            })
        }else{
            ElMessage({
                message: resp.data.msg,
                type: 'success',
            })
        }
    }
    return resp
}, async err => {
    if (err.response.status !== 200) {
        ElMessage({
            message: `请求出错,HTTP${err.response.status}`,
            type: 'error',
        })
        if (err.response.status===401){
                router.push({name:'LoginAdmin'})
        }
    }
    return Promise.reject(err);
})


export default request