<template>
<div id="test"></div>
</template>

<script>
const QRCode = require("qrcodejs2");
export default {
  name: "test.vue",
  mounted() {
    this.$nextTick(()=>{

      new QRCode('test', {
        // text:  IP+':8081/traceCode/info.html?traceCode='+_rows[i].traceCode,
        text: "UM3VSMJTY3_dq",
        width: 400,
        height: 400,
        // colorDark: "",
        // colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });

    })
  }
}
</script>

<style scoped>

</style>