<template>
  <div class="block">
    <el-cascader
        placeholder="请选择店铺(请不要选择太多店铺!)"
        :options="stores"
        v-model="selectStores"
        filterable
        :props="{
        value: 'id',
        label: 'name', // 指定显示name字段
        children: 'children',
        multiple: true,
    }" clearable>

    </el-cascader>

    <el-button @click="addManyStoreInfo.dialog=true" type="info">一键导入</el-button>
    <el-button @click="selectAll" type="warning">全选</el-button>
    <el-button @click="selectMainStore" type="danger">快速导入高库存店铺</el-button>

    <el-dialog v-model="addManyStoreInfo.dialog" width="80%">
      <el-form>
        <el-form-item>
          <el-input type="textarea" placeholder="请按行分隔输入StoreId" min="5" max="100"
                    v-model="addManyStoreInfo.inputStoreId"></el-input>
        </el-form-item>
        <span class="dialog-footer">
          <el-button @click="addManyStoreInfo.dialog = false">取消</el-button>
          <el-button type="primary" @click="addManyStore">
            确认添加
          </el-button>
        </span>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "StoreCascader",
  props: {
    callBack: {
      type: Function,
      required: true,
      default: (data) => {
        console.log(`选择店铺,${JSON.stringify(data)}`)
      }
    },
    metastores: {
      type: Array,
      required: true,
      default: []
    }
  },
  data() {
    return {
      storeMap: {},
      stores: [],
      selectStores: [],
      addManyStoreInfo: {
        dialog: false,
        stores: [],
        inputStoreId: '',
      },
      mainStoreIds: [
        1,
        3239,
        3573,
        2637,
        111,
        2307,
        491,
        1804,
        2662,
        1328,
        6353,
        3985,
        1774,
        2404,
        1733,
        4148,
        1784,
        6147,
        1198,
        1063,
        4146,
        1779,
        2226,
        4145,
        7024,
        7069,
        6950,
        1384,
        3238,
        6656,
        1750,
        2634,
        3371,
        7072,
        3075,
        1356,
        414,
        2588,
        2544,
        1720,
        1199,
        1227,
        3378,
        6692,
        6789,
        2416,
        2920,
        3076,
        2559,
        3879,
        1995,
        3668,
        377,
        6324,
        1086,
        3646,
        4087,
        1168,
        2225,
        1501,
        839,
        3882,
        760,
        3313,
        1165,
        784,
        2396,
        6891,
        6827,
        3336,
        6720,
        6801,
        3595,
        1051,
        6773,
        6255,
        495,
        2540,
        864,
        781,
        1133,
        2210,
        3973,
        3232,
        3382,
        3976,
        6761,
        6857,
        6878,
        3080,
        1059,
        2832,
        1796,
        3376,
        6954,
        2836,
        3783,
        3644,
        6859,
        2133,
        747,
        6653,
        6822,
        6856,
        2569,
        4088,
        4086,
        155,
        6778,
        207,
        2542,
        6861,
        1340,
        6889,
        3972,
        6637,
        6665,
        462,
        1832,
        2668,
        4147,
        4081,
        4082,
        2563,
        6737,
        3975,
        3091,
        2534,
        6898,
        415,
        3236,
        6399,
        149,
        1797,
        2584,
        3844,
        7027,
        300,
        3881,
        319,
        269,
        144,
        3641,
        2537,
        2963,
        2538,
        2553,
        2191,
        899,
        6708,
        1330,
        2359,
        2123,
        3084,
        776,
        2653,
        2958,
        6326,
        273,
        1155,
        2672,
        2254,
        3784,
        2400,
        2956,
        7068,
        2828,
        3372,
        6917,
        2955,
        2407,
        3240,
        2131,
        2221,
        2574,
        543,
        961,
        3974,
        1170,
        2257,
        1610,
        6926,
        3081,
        1646,
        3979,
        4153,
        2570,
        2890,
        378,
        2568,
        1361,
        2807,
        4150,
        6865,
        6762,
        3242,
        3234,
        859,
        2543,
        2581,
        1539,
        2663,
        2290,
        2399,
        3231,
        2966,
        3535,
        1065,
        203,
        6752,
        6233,
        3300,
        4085,
        6864,
        6209,
        7044,
        6774,
        6658,
      ]

    }
  },
  methods: {
    reverse_select() {
      let allIds = []
      for (let city of this.stores) {
        for (let store of city["children"]) {
          allIds.push(store.id)
        }
      }
      let newSelectStores = []
      for (let store_id of this.stores) {

        if (!this.selectStores.includes([0, store_id.id])) {
          newSelectStores.push([0, store_id.id])
        }
      }
    },
    getStore() {
      return this.$api.getStore().then(rep => {
        if (rep.data.code === this.$CODE.SUCCESS) {
          for (let key in rep.data.data) {
            let value = rep.data.data[key]
            let obj = {
              id: 1,
              name: key,
              children: []
            }
            this.stores.push(obj)
            for (let storeData of value) {
              obj.children.push({
                id: storeData.store_id,
                name: `${storeData.name} | ${storeData.store_id}`,
              })
              this.storeMap[storeData.store_id] = {
                store_id: storeData.store_id,
                store_name: storeData.name,
              }
            }
          }
        }
      })
    },
    addManyStore() {
      let lines = this.addManyStoreInfo.inputStoreId.split("\n");
      let intData = [];
      for (let line of lines) {
        // 尝试将每行数据转换为整数
        let num = parseInt(line);
        // 如果转换成功，则添加到数组中
        if (!isNaN(num)) {
          intData.push(num);
        }
      }
      return this.addManyStoreByIdsArray(intData, true)
    },
    selectAll() {
      this.selectStores = []
      for (let city of this.stores) {
        for (let store of city["children"]) {
          this.selectStores.push([1, store.id])
          console.log(store.id)
        }
      }
    },
    selectMainStore() {
      this.selectStores = []
      for (let store_id of this.mainStoreIds) {
        this.selectStores.push([1, store_id])
      }
    },
    addManyStoreByIdsArray(intData, needMsg) {
      let allIds = []
      for (let city of this.stores) {
        let cityNmae = city['name']
        for (let store of city["children"]) {
          allIds.push(store.id)
        }
      }
      var sucCount = 0
      for (let storeId of intData) {
        if (allIds.includes(storeId)) {
          if (!this.selectStores.includes([1, storeId])) {
            sucCount = sucCount + 1
          }
          this.selectStores.push([1, storeId])
        }
      }
      if (needMsg) {
        sucCount ? ElMessage.success('添加成功,共计' + sucCount + '个店铺!') : ElMessage.warning('添加失败,店铺ID均不存在!')
      }
    }
  },
  beforeMount() {
    this.getStore()
  },
  watch: {
    'selectStores': {
      handler(newValue, oldVal) {
        let result
        if (newValue) {
          result = newValue.map(innerArr => innerArr[innerArr.length - 1]);
        } else {
          result = newValue
        }
        let resultMapArray = []
        let selectIDs = []
        for (let store_id of result) {
          if (!selectIDs.includes(store_id)){
            resultMapArray.push(this.storeMap[store_id])
            selectIDs.push(store_id)
          }
        }
        this.callBack(resultMapArray)
      },
      deep: true,
      immediate: true,
    },
    'metastores': {
      handler(newValue, oldVal) {
        if (this.stores.length) {
          if (newValue) {
            this.selectStores = []
            this.addManyStoreByIdsArray(newValue)
          }
        } else {
          this.getStore().then(rep => {
            if (newValue) {
              this.selectStores = []
              this.addManyStoreByIdsArray(newValue)
            }
          })
        }

      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>