<template>
  <el-button @click="searchDialogVisible=true">打开查询</el-button>
  <el-dialog
      v-model="searchDialogVisible"
      title="如果搜的慢,请点击两次,将使用缓存"
      width="80%"
      align-center
  >
    <el-form>
      <el-form-item label="关键词搜索">
        <el-input placeholder="请输入关键词搜索商品" v-model="searchWord">
          <template #append>
            <el-button @click="searchGoods" :icon="Search">搜索</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="在哪个店铺ID搜索(默认为线上店铺)">
        <el-input placeholder="请输入店铺ID,默认1为线上店铺" type="number" v-model="store_id">
        </el-input>
      </el-form-item>
      <el-form-item label="SkuID搜索">
        <el-input placeholder="通过SkuID搜索" v-model="searchSkuId">
          <template #append>
            <el-button @click="searchWordBySkuID" :icon="Search">搜索</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="常用Sku选择">
        <el-select v-model="selectSku" @change="handleOftenSelect" placeholder="请选择商品">
          <el-option
              v-for="item in oftenGoodsInfo"
              :key="item.id"
              :label="`${item.shortName}|${(item.price / 100).toFixed(2)}元|ID:${item.id}`"
              :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>


    <template #footer>
      <span class="dialog-footer">
        <el-button @click="searchDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addData">确认选择</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="tableGoodsDialog">
    <el-table :data="goodsList" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"/>
      <el-table-column prop="name" label="名称" width="180"/>
      <el-table-column label="价格">
        <template #default="scope">
          <span v-text="scope.row.price/100"></span>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="price" label="Address"/>-->
      <el-table-column label="预览">
        <template #default="scope">
          <img :src="scope.row.img" style="height: 10vh;width: auto">
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" @click="this.addData(scope.row)">选择该商品</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

</template>

<script>
import {searchGoods, searchWordBySkuID} from "@/API/api";
import {ElMessage} from "element-plus"
import {Search} from "@element-plus/icons";

export default {
  name: "SearchGoods",
  data() {
    return {
      searchDialogVisible: false,
      tableGoodsDialog: false,
      goodsList: [],
      searchWord: "",
      store_id: 1,
      searchSkuId: "",
      selectSku: {},
      oftenGoodsInfo: [
        {
          "id": 3178,
          "name": "天线宝宝系列陪伴手办",
          "price": 6900,
          "shortName": "天线宝宝-测试专用",
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/17141193389070CG079kj73158aNnD8G6FEt1bREfx1w7.jpg"
        },
        {
          "id": 3495,
          "name": "【乐园限定】MOKOKO甜心系列 - CLOSE TO SWEET 搪胶毛绒公仔挂件",
          "price": 9900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1720417601526R0mSDKShy5nn0APS11Tsx3701j2h7X4Z.png",
          "shortName": "乐园mokoko"
        },
        {
          "id": 3494,
          "name": "【乐园限定】MOKOKO甜心系列 - CLOSE TO SWEET 搪胶毛绒公仔",
          "price": 39900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1720492920753Ed2x59kPcY7SRX7zmB0NjmjF22Kp2zYW.png",
          "shortName": "乐园mokoko"
        },
        {
          "id": 3352,
          "name": "【乐园限定】泡泡夏日派对系列-The Blue Diamond 搪胶毛绒公仔挂件",
          "price": 15900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1718617605264zZ4pdfP23XdfHZ6WQ16X6A6wB1mBzy0Z.png",
          "shortName": "乐园夏日"
        },
        {
          "id": 2473,
          "name": "THE MONSTERS - 心动马卡龙搪胶脸盲盒",
          "price": 9900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1697792114737h79dcxBM1KmHdhP1t4F1a1cpB7XWhn61.png",
          "shortName": "马卡龙一代"
        },
        {
          "id": 3468,
          "name": "THE MONSTERS - 坐坐派对搪胶毛绒盲盒",
          "price": 9900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1720176334098Hdi3BkXTaR2MSMKMt3625pRJJ7cyn7FP.jpg",
          "shortName": "坐坐"
        },
        {
          "id": 3149,
          "name": "CRYBABY × 飞天小女警系列-搪胶脸毛绒盲盒",
          "price": 7900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1713509047548KfS7C0443MNw7aXcS6i3P440PR87sQBx.jpg",
          "shortName": "女警"
        },
        {
          "id": 3339,
          "name": "CRYBABY落日歌会系列-毛绒挂件盲盒",
          "price": 7900,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1718345240375EewFwGXihf81asAKen7xDYsp0Rk4Ai7d.jpg",
          "shortName": "哭娃-落日歌会"
        },    {
          "id": 3888,
          "name": " 米兰 199",
          "price": 19999,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1713509047548KfS7C0443MNw7aXcS6i3P440PR87sQBx.jpg",
          "shortName": " 米兰 199"
        },
        {
          "id": 3887,
          "name": "米兰 599",
          "price": 59999,
          "img": "https://res.paquapp.com/popmartvip/goStore/goods/1718345240375EewFwGXihf81asAKen7xDYsp0Rk4Ai7d.jpg",
          "shortName": "米兰 599"
        },
      ]
    }
  },
  methods: {
    handleOftenSelect() {
      if (!this.selectSku) {
        this.$messageBox.alert('请选择商品!')
      } else {
        this.callBack(this.selectSku)
        this.searchDialogVisible = false
      }
    },
    searchGoods() {
      if (!this.searchWord) {
        ElMessage({
          showClose: true,
          message: '请输入关键词查询!',
          type: 'warning',
        })
        return
      }
      this.$api.searchGoods({"word": this.searchWord, "store_id": this.store_id}).then(rep => {
        if (rep.data.success) {
          this.tableGoodsDialog = true
          this.goodsList = rep.data.data
        }
      })
    },
    addData(data) {
      this.searchDialogVisible = false
      this.tableGoodsDialog = false
      this.callBack(data)
    },
    searchWordBySkuID() {
      let skuId = this.searchSkuId
      this.$api.searchWordBySkuID({skuId: skuId}).then(rep => {
        if (rep.data.success) {
          this.tableGoodsDialog = true
          this.goodsList = rep.data.data
        }
      })
    },
    selectGoodsByID() {
      let skuId = this.searchSkuId

    }
  },
  props: {
    callBack: {
      type: Function,
      required: true,
      default: (data) => {
        console.log(`查询商品,${JSON.stringify(data)}`)
      }
    },
    metaSku: {
      type: Object || Number,
      required: true,
    },
  },
  watch: {
    'metaSku': {
      handler(newValue, oldVal) {
        if (newValue) {
          if(Number.isInteger(newValue)){
            this.$api.searchWordBySkuID({skuId: newValue}).then(rep => {
              if (rep.data.success) {
                // this.tableGoodsDialog = true
                // this.goodsList = rep.data.data
                this.addData(rep.data.data[0])
              }
            })
          }else{
            this.callBack(newValue)
          }

        }

      },
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>