<template>
  <el-card shadow="hover">
    <div style="padding: 24px;">
      <div style="width: 100%;margin-bottom: 24px;">
        <el-form>
          <el-form-item>
            <el-input v-model="word" placeholder="根据任务名称搜索"/>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="timeSelect"
                type="datetimerange"
                :shortcuts="shortcuts"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model="taskStatus" placeholder="任务状态" clearable>
              <el-option
                  v-for="item in taskStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="fuzzySearchClick" type="primary" :loading="isLoading">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="addButtonFunc" type="warning" :loading="isLoading">添加任务</el-button>
          </el-form-item>
          <el-form-item>
            <el-popconfirm title="确定要删除所有任务记录,该操作不可撤回!" confirm-button-text="确认删除"
                           cancel-button-text="取消" @confirm="deleteAllTask">
              <template #reference>
                <el-button type="danger" :loading="isLoading">删除当页所有任务</el-button>
              </template>
            </el-popconfirm>
          </el-form-item>
        </el-form>
        <!--        <el-row>-->
        <!--          <el-col :span="3">-->
        <!--            <el-input v-model="word" placeholder="根据任务名称搜索"/>-->
        <!--          </el-col>-->
        <!--          <el-col :span="1">-->
        <!--          </el-col>-->
        <!--          <el-col :span="4">-->
        <!--            <el-date-picker-->
        <!--                v-model="timeSelect"-->
        <!--                type="datetimerange"-->
        <!--                :shortcuts="shortcuts"-->
        <!--                range-separator="至"-->
        <!--                start-placeholder="开始日期"-->
        <!--                end-placeholder="结束日期"-->
        <!--                align="right"></el-date-picker>-->
        <!--          </el-col>-->
        <!--          <el-col :span="5"></el-col>-->
        <!--          <el-col :span="2">-->
        <!--            <el-select v-model="taskStatus" placeholder="任务状态" clearable>-->
        <!--              <el-option-->
        <!--                  v-for="item in taskStatusOptions"-->
        <!--                  :key="item.value"-->
        <!--                  :label="item.label"-->
        <!--                  :value="item.value">-->
        <!--              </el-option>-->
        <!--            </el-select>-->
        <!--          </el-col>-->
        <!--          <el-col :span="2">-->
        <!--            <div class="grid-content ep-bg-purple-light"/>-->
        <!--            <el-button @click="fuzzySearchClick" type="primary" :loading="isLoading">搜索</el-button>-->
        <!--          </el-col>-->
        <!--          <el-col :span="2">-->
        <!--            <div class="grid-content ep-bg-purple-light"/>-->
        <!--            <el-button @click="addButtonFunc" type="warning" :loading="isLoading">添加任务-->
        <!--            </el-button>-->
        <!--          </el-col>-->
        <!--          <el-col :span="2">-->
        <!--            <div class="grid-content ep-bg-purple-light"/>-->
        <!--            <el-popconfirm title="确定要删除所有任务记录,该操作不可撤回!" confirm-button-text="确认删除"-->
        <!--                           cancel-button-text="取消" @confirm="deleteAllTask">-->
        <!--              <template #reference>-->
        <!--                <el-button type="danger" :loading="isLoading">删除当页所有任务</el-button>-->
        <!--              </template>-->
        <!--            </el-popconfirm>-->
        <!--          </el-col>-->

        <!--        </el-row>-->
      </div>
      <div style="width: 100%;">
        <el-table :data="tableData" style="width: 100%" border :cell-style="{textAlign:'center'}"
                  :header-cell-style="{textAlign:'center'}"
                  v-loading="isLoading"
                  :row-style="rowClassName"
                  :default-sort="{prop: 'date'}"
        >
          <el-table-column label="index" width="50" align="center">
            <template #default="scope">
              <div>
                <span>{{ scope.row.index }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务名称" :show-overflow-tooltip="true">
            <template #default="scope">
              <div>
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务类型" :show-overflow-tooltip="true" width="100">
            <template #default="scope">
              <div>
                <span>{{ scope.row.type }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务唯一标识符" width="180">
            <template #default="scope">
              <div>
                <span>{{ scope.row.task_id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务开始时间" :show-overflow-tooltip="true" width="160">
            <template #default="scope">
              <div>
                <span>{{ scope.row.start_time_text }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务状态" width="100">
            <template #default="scope">
              <div>
                <span style="color: green" v-if="scope.row.status">进行中</span>
                <span style="color: gray" v-if="!scope.row.status">已取消</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" @click="getTaskLog(scope.row)">
                任务日志
              </el-button>
              <el-button
                  size="small"
                  type="info"
                  @click="cancelTask([scope.row.task_id])"
              >
                取消
              </el-button>
              <el-button
                  size="small"
                  type="danger"
                  @click="deleteTask([scope.row.task_id])"
              >删除
              </el-button>
              <el-button
                  size="small"
                  type="warning"
                  @click="importTask(scope.row)"
              >一键导入任务
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-card>
  <el-dialog v-model="addTaskDialog" title="添加定时/监控任务" draggable width="90%">
    <el-radio v-model="addTaskInfo.taskType" :label="1" @click="addTaskInfo.mustData.max_time=600">监控捡漏
    </el-radio>
    <el-radio v-model="addTaskInfo.taskType" :label="2" @click="addTaskInfo.mustData.max_time=30">定时抢购
    </el-radio>
    <el-form>
      <el-form-item label="选择商品" v-if="addTaskInfo.taskType!==4">
        <el-input placeholder="请选择商品" v-model="addTaskInfo.goodsInfoText">
          <template #append>
            <search-goods :call-back="goodsSelectCallBack" :meta-sku="metaSku"></search-goods>
          </template>
        </el-input>

      </el-form-item>
      <el-form-item label="选择店铺">
        <store-cascader :call-back="storeSelectCallBack" :show-all-levels="false" :metastores="metaStores"
                        demonstration></store-cascader>
      </el-form-item>
      <el-form-item :label="addTaskInfo.taskType===2?'抢购持续时长/秒':'监控持续时长/秒 (建议不要太长)'">
        <el-input-number v-model="addTaskInfo.mustData.max_time" :min="10" :max="3600000*10" :step="60"/>
      </el-form-item>
      <el-form-item :label="'用哪些号做监控\n(不选择则所有用户)'" v-if="addTaskInfo.taskType===1"
                    class="red-el-item label-n">
        <el-select v-model="addTaskInfo.MonitorForm.monitor_wxids" placeholder="建议使用黑号监控" multiple clearable
                   filterable>
          <el-button @click="monitor_wxids_reverse">一键反选</el-button>
          <el-option v-for="user in userInfos" :label="`${user.nickname}|${user.phone}`" :value="user.wxid"
                     :key="user.wxid"></el-option>
        </el-select>
        <el-button @click="importWxidDialog=true;importWxidType='monitor'">一键导入WXID</el-button>

      </el-form-item>
      <el-form-item :label="'参与抢购用户\n(不选择则所有用户)'" v-if="addTaskInfo.taskType" class="red-el-item label-n">
        <el-select v-model="addTaskInfo.mustData.buy_users_wxids" placeholder="请选择参与抢购用户" multiple clearable
                   filterable>
          <el-button @click="monitor_wxids_reverse">一键反选</el-button>
          <el-option v-for="user in userInfos" :label="`${user.nickname}|${user.phone}`" :value="user.wxid"
                     :key="user.wxid"></el-option>
        </el-select>
        <el-button @click="importWxidDialog=true;importWxidType='buy'">一键导入WXID</el-button>

      </el-form-item>

      <el-form-item label="单用户监控访问频率/秒" v-if="addTaskInfo.taskType===1">
        <el-input-number v-model="addTaskInfo.MonitorForm.user_wait_time" :min="1" :max="9999999" :step="0.1"/>
      </el-form-item>
      <el-form-item label="每个账号多少秒下一次单(秒!!)">
        <el-input-number :min="0.1" :max="9999999999" :step="0.1"
                         v-model="addTaskInfo.mustData.buy_wait_time"></el-input-number>
      </el-form-item>

      <el-form-item label="默认购买数量">
        <el-input-number v-model="addTaskInfo.mustData.count" :min="1" :max="1000" :step="1"/>
      </el-form-item>
      <el-form-item label="提前发请求毫秒数" v-if="addTaskInfo.taskType===2">
        <el-input-number v-model="addTaskInfo.SnappedForm.milliseconds" :min="0" :max="5000" :step="100"/>
      </el-form-item>
      <el-form-item :label="'请选择定时时间\n也可定时开始监控'" class="label-n">
        <div class="block">
          <el-date-picker
              v-model="addTaskInfo.mustData.start_time"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addTaskDialog = false" disabled>取消任务</el-button>
        <el-button type="primary" @click="addTask" v-loading="addTaskLoading">
          提交任务
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="logInfo.dialog" width="80%" draggable>
    <el-card v-loading="logInfo.loading">
      <!--      <el-button type="info" @click="completeTask">查看完整日志</el-button>-->
      <el-button type="primary" @click="TaskLogToExcel">导出为Excel</el-button>
      <el-button type="warning" @click="flushedTaskLog">刷新日志</el-button>
      <el-input
          type="textarea"
          :rows="logInfo.logRow"
          placeholder="任务日志"
          v-model="logInfo.logText">
      </el-input>
    </el-card>

  </el-dialog>
  <el-dialog v-model="importWxidDialog" width="90%">
    <el-form>
      <h1>导入WXID - {{ importWxidType === "buy" ? "购买用户" : "监控用户" }}</h1>
      <el-form-item label="导入WXID">
        <el-input type="textarea" placeholder="请按行分隔输入WXID" v-model="importWxidText"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="importWxid">确认导入</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {ElMessageBox, ElMessage} from "element-plus";
import {
  deleteTask,
  getPopmartUserCache,
  getStore,
  getTaskLog, newTaskDelete, newTaskLog,
  StartExtractStock,
  startExtractStockAPIView
} from "@/API/api";
import SearchGoods from "@/components/SearchGoods.vue";
import StoreCascader from "@/components/StoreCascader.vue";
import moment from 'moment'
import {ElNotification} from 'element-plus'
import {Watermark} from "@/components/watermark";

export default {
  components: {StoreCascader, SearchGoods},
  data() {
    return {
      isLoading: false,
      importWxidDialog: false,
      importWxidType: '',
      word: "",
      importWxidText: "",
      tableData: [],
      addTaskDialog: false,
      timeSelect: [],
      metaStores: [],
      metaSku: null,
      addTaskInfo: {
        taskType: 1,
        goodsInfoText: '',
        goodsInfo: {},
        mustData: {
          sku: null,
          stores: [],
          count: 1,
          max_time: 120,
          buy_wait_time: 1.5,
          start_time: Date.now(),
          buy_users_wxids: []
        },
        MonitorForm: {
          need_buy: true,
          buy_time: 60,
          monitor_wxids: [],
          user_wait_time: 5.5,
          monitor_frequency: 1.5
        },
        SnappedForm: {
          milliseconds: 200,
        }
      },
      addTaskLoading: false,
      statusStyleMap: {
        0: 'blue',
        1: 'green',
        2: 'red',
        3: 'pink'
      },
      logInfo: {
        logText: "",
        logRow: 100,
        dialog: false,
        nowLog: '',
        loading: false,
      },
      shortcuts: [
        {
          text: '最近1分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 1);
            return [start, end]
          }
        }, {
          text: '最近5分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 5);
            return [start, end]
          }
        }, {
          text: '最近10分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 10);
            return [start, end]
          }
        }, {
          text: '最近30分钟',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 30);
            return [start, end]
          }
        }, {
          text: '最近1小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60);
            return [start, end]
          }
        }, {
          text: '最近10小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 10);
            return [start, end]
          }
        }, {
          text: '最近24小时',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24);

            return [start, end]
          }
        }, {
          text: '最近3天',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 3);

            return [start, end]
          }
        }, {
          text: '最近7天',
          value() {
            const end = new Date();
            let start = new Date();
            start = start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 7);

            return [start, end]
          }
        },
      ],
      taskStatusOptions: [{
        value: 1,
        label: "开始",
      }, {
        value: 0,
        label: "结束",
      }
      ],
      taskStatus: null,
      userInfos: []
    }

  },
  methods: {
    importWxid() {
      let wxids = this.importWxidText.split('\n')
      if (this.importWxidType === "buy") {
        this.addTaskInfo.mustData.buy_users_wxids = []
        for (let wxid of wxids) {
          this.addTaskInfo.mustData.buy_users_wxids.push(wxid)
        }
      } else {
        this.addTaskInfo.MonitorForm.monitor_wxids = []
        for (let wxid of wxids) {
          this.addTaskInfo.MonitorForm.monitor_wxids.push(wxid)
        }
      }

      this.importWxidDialog = false
    },
    addButtonFunc() {
      this.addTaskDialog = true;
      ElNotification({
        title: '警告',
        message: 'V2.3版本后原"不参与监控用户"更改为->"参与监控用户",请仔细配置!',
        type: 'warning',
      })
      this.getCacheUserInfos();
    },

    setWatermark(targetNode, content, attrs) {
      let watermark = new Watermark({
        targetNode, // 需要添加水印的容器类名
        content, // 水印内容
        width: 100,
        height: 80,
        ...attrs, // 其他相关扩展属性看watermark.js文件
      });
      watermark.create();
    },
    rowClassName({row, rowIndex}) {
      let css = this.statusStyleMap[row.status] ? {color: this.statusStyleMap[row.status]} : ''
      return css;
    },
    getCacheUserInfos() {
      this.$api.getPopmartUserCache().then(rep => {
        this.userInfos = rep.data.data
      })
    },
    goodsSelectCallBack(goods) {
      this.addTaskInfo.goodsInfo = goods
      this.addTaskInfo.goodsInfoText = `ID:${goods.id}|${goods.name}|${goods.price / 100}`
      this.addTaskInfo.mustData.sku = goods
      console.log(goods)
    },
    storeSelectCallBack(stores) {
      this.addTaskInfo.mustData.stores = stores
    },
    handleClose() {
      this.dialogVisible = false
    },
    getTaskList(params) {
      this.isLoading = true
      return this.$api.newTaskList(params).then(resp => {
        this.tableData = resp.data.data
        if (resp.data.data.length === 0) {
          // 检索到用户
          ElMessage.warning('未找到任务')
        }
        return resp
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    },
    cancelTask(task_ids) {
      this.$api.newTaskCancel(task_ids).then(rep => {
        if (!rep.data.success) {
          ElMessageBox.alert(rep.data.msg, '提醒', {
            confirmButtonText: '确认',
            type: "info",
            callback: () => {
            }
          })
        }

      })
    },
    deleteTask(task_ids) {
      this.$api.newTaskDelete(task_ids).then(rep => {
        // ElMessageBox.alert(rep.data.msg, '提醒', {
        //   confirmButtonText: '确认',
        //   type: "info",
        //   callback: () => {
        //   }
        // })
        this.tableData = this.tableData.filter(item => !task_ids.includes(item.task_id))
      })
    },
    importTask(task) {
      // console.log(task)
      let meta_data = task.meta_data
      if (!meta_data) {
        ElMessageBox.alert('任务非用户发起或原数据丢失')
        return
      }
      this.addTaskInfo.taskType = meta_data.type
      // this
      this.metaSku = meta_data.sku
      this.addTaskDialog = true
      if (this.addTaskInfo.taskType === 1) {
        ElNotification({
          title: '警告',
          message: 'V2.3版本后原"不参与监控用户"更改为->"参与监控用户",请仔细配置!',
          type: 'warning',
        })
      }
      this.getCacheUserInfos()
      this.addTaskInfo.mustData.sku = meta_data.sku
      // console.log(this.metaStores)
      this.metaStores = []
      for (let store of meta_data.stores) {
        if (store.store_id) {
          this.metaStores.push(store.store_id)
        } else {
          this.metaStores.push(store)
        }
      }
      // console.log(this.metaStores)
      this.addTaskInfo.mustData.count = meta_data.count
      this.addTaskInfo.mustData.max_time = meta_data.max_time
      this.addTaskInfo.mustData.buy_wait_time = meta_data.buy_wait_time
      this.addTaskInfo.mustData.start_time = meta_data.start_time * 1000
      this.addTaskInfo.mustData.buy_users_wxids = meta_data.buy_users_wxids
      if (meta_data.user_wait_time) {
        this.addTaskInfo.taskType = 1
        this.addTaskInfo.MonitorForm.monitor_wxids = meta_data.monitor_wxids
        this.addTaskInfo.MonitorForm.user_wait_time = meta_data.user_wait_time
        this.addTaskInfo.MonitorForm.monitor_frequency = meta_data.monitor_frequency
        this.addTaskInfo.MonitorForm.buy_time = meta_data.buy_time

      } else {
        this.addTaskInfo.taskType = 2
        this.addTaskInfo.SnappedForm.milliseconds = meta_data.milliseconds


      }


      // this.$api.deleteTask({task: task_ids}).then(rep => {
      //   // ElMessageBox.alert(rep.data.msg, '提醒', {
      //   //   confirmButtonText: '确认',
      //   //   type: "info",
      //   //   callback: () => {
      //   //   }
      //   // })
      //   this.tableData = this.tableData.filter(item => !task_ids.includes(item.id))
      // })
    },
    deleteAllTask() {
      let deleteTaskIds = []
      if (!this.tableData.length) {
        ElMessage.warning('当前页面无任务!')
        return
      }
      for (let task of this.tableData) {
        deleteTaskIds.push(task.task_id)
      }
      this.deleteTask(deleteTaskIds)
      this.getTaskList()
    },
    fuzzySearchClick() {
      let start_time = null
      let end_time = null
      if (this.timeSelect && this.timeSelect.length === 2) {
        start_time = this.timeSelect[0]
        end_time = this.timeSelect[1]
        start_time = start_time ? parseInt(Date.parse(start_time) / 1000) : null
        end_time = start_time ? parseInt(Date.parse(end_time) / 1000) : null
      }
      this.getTaskList({word: this.word, start_time, end_time, status: this.taskStatus})
    },
    addTask() {
      console.log(JSON.stringify(this.addTaskInfo.goodsInfo))
      // if(this.addTaskInfo.goodsInfo)
      this.addTaskLoading = true
      let data = {...this.addTaskInfo.mustData}
      data.start_time = Math.floor(data.start_time / 1000)
      if (this.addTaskInfo.taskType === 1) {
        data = {...data, ...this.addTaskInfo.MonitorForm}
        console.log(data)
        this.$api.newTaskMonitor(data).then(rep => {
          if (rep.data.code === this.$CODE.SUCCESS) {
            this.addTaskDialog = false
            ElMessageBox('添加监控任务成功!' + rep.data.msg, {
              type: 'success',
            })
            this.word = rep.data.data.task
          } else {
            ElMessageBox('添加监控任务失败' + rep.data.msg, {
              type: 'warning',
            })
          }
        }).finally(() => {
          setTimeout(() => {
            this.addTaskLoading = false
            this.getTaskList(
                {word: this.word}
            )
          }, 300)
        })
      } else if (this.addTaskInfo.taskType === 2) {
        data = {...data, ...this.addTaskInfo.SnappedForm}

        this.$api.newTaskBuy(data).then(rep => {
          if (rep.data.success) {
            this.addTaskDialog = false
            ElMessageBox.alert('添加抢购任务成功!' + rep.data.msg, {
              type: 'success',
            })
            this.word = rep.data.data.task
          } else {
            ElMessageBox.alert('添加抢购任务失败' + rep.data.msg, {
              type: 'warning',
            })
          }
        }).finally(() => {
          setTimeout(() => {
            this.addTaskLoading = false
            this.getTaskList(
                {word: this.word}
            )
          }, 300)
        })
      }


    },
    monitor_wxids_reverse() {
      let new_monitor_wxids = []
      for (let user of this.userInfos) {
        if (!this.addTaskInfo.MonitorForm.monitor_wxids.includes(user.wxid)) {
          new_monitor_wxids.push(user.wxid)
        }
      }
      this.addTaskInfo.MonitorForm.monitor_wxids = new_monitor_wxids
    },
    buy_wxids_reverse() {
      let new_nobuy_wxids = []
      for (let user of this.userInfos) {
        if (!this.addTaskInfo.mustData.nobuy_wxids.includes(user.wxid)) {
          new_nobuy_wxids.push(user.wxid)
        }
      }
      this.addTaskInfo.mustData.nobuy_wxids = new_nobuy_wxids
    },
    getTaskLog(taskInfo) {
      this.$api.newTaskLog({task: taskInfo.task_id}).then(rep => {
        if (rep.data.success) {
          this.logInfo.nowLog = taskInfo
          this.logInfo.logText = rep.data.data.text
          this.logInfo.logRow = rep.data.data.rows + 5
          this.logInfo.dialog = true
        }
      })

    },
    completeTask() {
      let taskInfo = this.logInfo.nowLog
      this.logInfo.loading = true
      this.$api.newTaskLog({task: taskInfo.task_id, complete: 1}).then(rep => {
        if (rep.data.success) {
          this.logInfo.nowLog = taskInfo
          this.logInfo.logText = rep.data.data.text
          this.logInfo.logRow = rep.data.data.rows + 5
        }
      }).finally(() => {
        this.logInfo.loading = false
      })
    },
    flushedTaskLog() {
      let taskInfo = this.logInfo.nowLog
      this.logInfo.loading = true
      this.$api.newTaskLog({task: taskInfo.task_id}).then(rep => {
        if (rep.data.success) {
          this.logInfo.nowLog = taskInfo
          this.logInfo.logText = rep.data.data.text
          this.logInfo.logRow = rep.data.data.rows + 5
        }
      }).finally(() => {
        this.logInfo.loading = false
      })
    },
    TaskLogToExcel() {
      // const encoder = new TextEncoder('utf-8');
      // const csvArrayBuffer = encoder.encode(this.logInfo.logText);
      let taskName = this.logInfo.nowLog.name + this.logInfo.nowLog.task_id
      // 创建Blob对象
      const blob = new Blob(['\uFEFF', this.logInfo.logText.replaceAll('--', ',')], {type: 'text/csv;charset=utf-8;'});
      // 创建下载链接
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = taskName + '.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  beforeMount() {
    this.getTaskList()
  }
}
</script>

<style>
.red-el-item .el-form-item__label {
  color: #f56c6c;
  font-weight: bold;
}

.label-n {
  white-space: pre-line;
  /*text-align-last: justify;*/
  /*text-align:justify;*/
  margin-top: -4px;
  line-height: 25px;
  text-justify: distribute-all-lines;
}
</style>
<!--1. 账号（返回列表）
2. 选择店铺
3. 商品id
-->