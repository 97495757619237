<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2 class="login-title">后台管理登录</h2>
      <el-form class="login-form">
        <el-form-item>
          <el-input v-model="username" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="password" type="password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">管理前台登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import {LoginAdmin} from '@/API/api'
export default {
  name: 'Login',
  setup() {
    const username = ref('');
    const password = ref('');
    const router = useRouter();

    const login = async () => {
      if(!username.value||!password.value){
        return ElMessage.error('请填写完整');
      }
      try {
        const response = await LoginAdmin({ username: username.value, password: password.value });
        if (response.data.success) {
          window.sessionStorage.setItem('username', username.value);
          window.sessionStorage.setItem('password', password.value);
          ElMessage.success('登录成功');
          // _this.$router.push({ name: 'admin' });
          setTimeout(()=>{
            router.push({ name: 'Task' });

          },1000)

        } else {
          ElMessage.error('登录失败');
        }
      } catch (error) {
        console.log(error)
        ElMessage.error('出现错误!');
      }
    };

    return {
      username,
      password,
      login
    };
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  width: 400px;
  padding: 20px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}
</style>
